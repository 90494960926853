import React from "react";
import { CircularProgress } from "@material-ui/core";

const Loader = () => {
  return (
    <>
      {/* loader */}
      <div className="overlay">
        <CircularProgress />
      </div>
    </>
  );
};

export default Loader;
