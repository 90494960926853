import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import RSelect from "react-select";
import RSearchBar from "material-ui-search-bar";
import "./index.scss";
import "remixicon/fonts/remixicon.css";
import "primeicons/primeicons.css";
import { Link, useHistory } from "react-router-dom";
import { Tooltip } from "@mui/material";
import axios from "axios";
import constantObject from "../../config";
import { formatTimes } from "../../common/Functions";
import qubeimg from "../../assets/images/qube.png";
import document from "../../assets/images/doc.png";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  // TableHead,
  TableRow,
  TablePagination,
  Button,
} from "@material-ui/core";
import Loader from "../../components/Loader";

const HomePage = () => {
  const [blockTableData, setBlockTableData] = useState([]);
  const [transactionTableData, setTransactionTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchText, setSearchText] = useState("");

  const history = useHistory();

  // Function to handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Function to fetch updated block data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${constantObject.BaseUrl}/api/block?limit=${rowsPerPage}&page=${
            page + 1
          }`
        );

        const { data } = response.data;

        setBlockTableData(data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    // Fetch data immediately on mount
    fetchData();

    // Set up interval for fetching updated data
    const intervalId = setInterval(fetchData, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [page, rowsPerPage]);

  // Function to fetch updated transaction data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${
            constantObject.BaseUrl
          }/api/transaction?limit=${rowsPerPage}&page=${page + 1}`
        );

        const { data } = response.data;

        setTransactionTableData(data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    // Fetch data immediately on mount
    fetchData();

    // Set up interval for fetching updated data
    const intervalId = setInterval(fetchData, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [page, rowsPerPage]);

  const options = [
    { value: "Txn", label: "Transaction" },
    { value: "block", label: "Block" },
    { value: "address", label: "Address" },
  ];

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);

    // Redirect to the selected page based on the value
    if (selectedOption) {
      switch (selectedOption.value) {
        case "Txn":
          history.push("/new_transactions");
          break;
        case "block":
          history.push("/new_block_page");
          break;
        case "address":
          history.push("/new_transactions");
          break;
        default:
          break;
      }
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
    setPage(0);
  };

  const filterBlockTable = blockTableData.filter(
    (row) =>
      String(row.blockNumber)
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      String(row.hash).toLowerCase().includes(searchText.toLowerCase())
  );

  const filterTxnTable = transactionTableData.filter(
    (row) =>
      String(row.blockNumber)
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      String(row.hash).toLowerCase().includes(searchText.toLowerCase()) ||
      String(row.from).toLowerCase().includes(searchText.toLowerCase()) ||
      String(row.to).toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      <Navbar />

      {/* loader */}
      {isLoading && <Loader />}

      <div className="searchbar-container">
        <div className="subheading">AMC Smart Chain Explorer</div>

        <div className="searchbar">
          <RSelect
            value={selectedOption}
            onChange={handleSelectChange}
            options={options}
            placeholder="All Filters"
            className="homepage-selectbox"
            styles={{
              control: (provided) => ({
                ...provided,
                border: "none",
                outline: "none",
                borderBottomRightRadius: "0px",
                borderTopRightRadius: "0px",
              }),
            }}
          />

          <RSearchBar
            onChange={handleSearch}
            onCancelSearch={() => handleSearch("")}
            placeholder="Search by Txn Hash / Block  / Address"
            className="homepage-searchbar"
            style={{
              width: "50%",
              height: "38px",
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
              border: "none",
              outline: "none",
            }}
          />
        </div>
      </div>

      <div className="home-page-container">
        {/* Custom scrollbar design */}
        <style>
          {`
            .custom-scrollbar::-webkit-scrollbar {
              width: 5px;
            }
            .custom-scrollbar::-webkit-scrollbar-track {
              background-color: #f1f1f1;
            }
            .custom-scrollbar::-webkit-scrollbar-thumb {
              background-color: #0784c3;
              border-radius: 4px;
            }
            .custom-scrollbar::-webkit-scrollbar-thumb:hover {
              background-color: #888;
            }

            /* For horizontal scrollbar */
            .custom-scrollbar::-webkit-scrollbar:horizontal {
              height: 5px;
              background-color: #f1f1f1;
            }
            .custom-scrollbar::-webkit-scrollbar-thumb:horizontal {
              background-color: #888;
              border-radius: 4px;
            }
            .custom-scrollbar::-webkit-scrollbar-thumb:horizontal:hover {
              background-color: #0784c3;
            }
          `}
        </style>

        {/* Block table */}
        <Paper className="paper-style table-container">
          <div className="home-page-heading">
            <div>
              <span className="dashboard-table-heading">Latest Blocks</span>
            </div>
            {/* <Button
              variant="outlined"
              color="default"
              size="small"
              style={{
                backgroundColor: "#F8F9FA",
                borderRadius: 8,
                display: "flex",
                alignItems: "center",
                textTransform: "capitalize",
              }}
            >
              <i
                className="ri-dashboard-line"
                style={{
                  color: "#0784C3",
                  marginRight: "5px",
                }}
              ></i>
              Customize
            </Button> */}
          </div>

          <TableContainer
            style={{ height: "608px" }}
            className="custom-scrollbar"
          >
            <Table>
              <TableBody>
                {(filterBlockTable.length || blockTableData.length) === 0 ? (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      No data found
                    </TableCell>
                  </TableRow>
                ) : (
                  (filterBlockTable.length
                    ? filterBlockTable
                    : blockTableData
                  ).map((row) => (
                    <TableRow key={row._id} hover>
                      <TableCell className="cell-style">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Button
                            variant="outlined"
                            size="small"
                            style={{
                              backgroundColor: "#F8F9FA",
                              borderRadius: 8,
                              minWidth: "28px",
                              marginRight: "16px",
                            }}
                          >
                            <img
                              src={qubeimg}
                              alt="Logo"
                              height={22}
                              width={22}
                            />
                          </Button>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Link
                              to={`/block_details/${row.blockNumber}`}
                              style={{
                                color: "#0784C3",
                                textDecoration: "none",
                              }}
                            >
                              {row.blockNumber}
                            </Link>
                            <span className="timing-style">
                              {formatTimes(row.timestamp)}
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className="cell-style">
                        <Tooltip title="Transactions in this block" arrow>
                          <Link
                            to={`/txn_for_block/${row.blockNumber}`}
                            style={{
                              color: "#0784C3",
                              textDecoration: "none",
                            }}
                          >
                            {row.transactionCount} transactions
                          </Link>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className="cell-style"
                        style={{ textAlign: "right" }}
                      >
                        <Tooltip title="Block Reward" arrow>
                          <Button
                            variant="outlined"
                            color="default"
                            size="small"
                            style={{
                              fontSize: "11px",
                              fontWeight: "400",
                              backgroundColor: "#F8F9FA",
                              borderRadius: 8,
                            }}
                          >
                            {row.reward + " AMC"}
                          </Button>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={filterBlockTable.length || blockTableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ display: "none" }}
            />
          </TableContainer>

          <div className="view-all-blocks-cant">
            <Link to="/new_block_page" style={{ textDecoration: "none" }}>
              <span className="view-all-blocks">View All Blocks</span>
              <i
                className="pi pi-arrow-right"
                style={{ fontSize: "10px", color: "#FFFFFF" }}
              ></i>
            </Link>
          </div>
        </Paper>

        {/* Transaction table */}
        <Paper className="paper-style table-container">
          <div className="home-page-heading">
            <div>
              <span className="dashboard-table-heading">
                Latest Transactions
              </span>
            </div>
            {/* <Button
              variant="outlined"
              color="default"
              size="small"
              style={{
                backgroundColor: "#F8F9FA",
                borderRadius: 8,
                display: "flex",
                alignItems: "center",
                textTransform: "capitalize",
              }}
            >
              <i
                className="ri-dashboard-line"
                style={{
                  color: "#0784C3",
                  marginRight: "5px",
                }}
              ></i>
              Customize
            </Button> */}
          </div>

          <TableContainer
            className="custom-scrollbar"
            style={{ height: "608px" }}
          >
            <Table>
              <TableBody>
                {(filterTxnTable.length || transactionTableData.length) ===
                0 ? (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      No data found
                    </TableCell>
                  </TableRow>
                ) : (
                  (filterTxnTable.length
                    ? filterTxnTable
                    : transactionTableData
                  ).map((row) => (
                    <TableRow key={row._id} hover>
                      <TableCell className="cell-style">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Button
                            variant="outlined"
                            size="small"
                            style={{
                              backgroundColor: "#F8F9FA",
                              borderRadius: 8,
                              minWidth: "28px",
                              marginRight: "16px",
                            }}
                          >
                            <img
                              src={document}
                              alt="Logo"
                              height={22}
                              width={22}
                            />
                          </Button>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Tooltip title={row.hash} arrow>
                              <Link
                                to={`/transactions_detail/${row.hash}`}
                                style={{
                                  color: "#0784C3",
                                  textDecoration: "none",
                                }}
                              >
                                {row.hash.length > 20
                                  ? `${row.hash.substring(0, 20)}...`
                                  : row.hash}
                              </Link>
                            </Tooltip>
                            <span className="timing-style">
                              {formatTimes(row.blockTimestamp)}
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className="cell-style">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div className="transaction-timing">
                            <span>From</span>
                            {row.tokenData && row.tokenData.from ? (
                              <Tooltip title={row.tokenData?.from} arrow>
                                <Link
                                  to={`/address/${row.tokenData?.from}`}
                                  style={{
                                    color: "#0784C3",
                                    textDecoration: "none",
                                  }}
                                >
                                  {row.tokenData?.from.length > 20
                                    ? `${row.tokenData?.from.substring(
                                        0,
                                        20
                                      )}...`
                                    : row.tokenData?.from}
                                </Link>
                              </Tooltip>
                            ) : (
                              <Tooltip title={row.from} arrow>
                                <Link
                                  to={`/address/${row.from}`}
                                  style={{
                                    color: "#0784C3",
                                    textDecoration: "none",
                                  }}
                                >
                                  {row.from.length > 20
                                    ? `${row.from.substring(0, 20)}...`
                                    : row.from}
                                </Link>
                              </Tooltip>
                            )}
                          </div>
                          <div className="transaction-timing">
                            <span>To</span>
                            {row.tokenData && row.tokenData.to ? (
                              <Tooltip title={row.tokenData?.to} arrow>
                                <Link
                                  to={`/address/${row.tokenData?.to}`}
                                  style={{
                                    color: "#0784C3",
                                    textDecoration: "none",
                                  }}
                                >
                                  {row.tokenData?.to.length > 20
                                    ? `${row.tokenData?.to.substring(0, 20)}...`
                                    : row.tokenData?.to}
                                </Link>
                              </Tooltip>
                            ) : (
                              <Tooltip title={row.to} arrow>
                                <Link
                                  to={`/address/${row.to}`}
                                  style={{
                                    color: "#0784C3",
                                    textDecoration: "none",
                                  }}
                                >
                                  {row.to.length > 20
                                    ? `${row.to.substring(0, 20)}...`
                                    : row.to}
                                </Link>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="cell-style"
                        style={{ textAlign: "right" }}
                      >
                        {row.tokenData && row.tokenData.value ? (
                          <Tooltip title="Amount" arrow>
                            <Button
                              variant="outlined"
                              color="default"
                              size="small"
                              style={{
                                fontSize: "11px",
                                fontWeight: "400",
                                backgroundColor: "#F8F9FA",
                                borderRadius: 8,
                              }}
                            >
                              {row.tokenData?.value}&nbsp;{row.tokenData?.token}
                            </Button>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Amount" arrow>
                            <Button
                              variant="outlined"
                              color="default"
                              size="small"
                              style={{
                                fontSize: "11px",
                                fontWeight: "400",
                                backgroundColor: "#F8F9FA",
                                borderRadius: 8,
                              }}
                            >
                              {row.value + " AMC"}
                            </Button>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={filterTxnTable.length || transactionTableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ display: "none" }}
            />
          </TableContainer>

          <div className="view-all-blocks-cant">
            <Link to="/new_transactions" style={{ textDecoration: "none" }}>
              <span className="view-all-blocks">View All Transaction</span>
              <i
                className="pi pi-arrow-right"
                style={{ fontSize: "10px", color: "#FFFFFF" }}
              ></i>
            </Link>
          </div>
        </Paper>
      </div>

      <Footer />
    </>
  );
};

export default HomePage;
