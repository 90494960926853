import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import csvicon from "../../assets/images/csv-icon.png";
import bulb from "../../assets/images/Vector.png";
import axios from "axios";
import "./index.scss";
import constantObject from "../../config";
import { CSVLink } from "react-csv";
import { Tooltip } from "@mui/material";
import { Link, useHistory, useParams } from "react-router-dom";
import { IoHelpCircleOutline } from "react-icons/io5";
import { localTimeConvert, formatTimes } from "../../common/Functions";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import Loader from "../../components/Loader";

const AddressForTxn = () => {
  const [cardValue, setCardValue] = useState({});
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // State to store transactions for export
  const [exportedData, setExportedData] = useState([]);

  const history = useHistory();
  const { ads } = useParams();

  const cardData = [
    {
      heading: "Overview",
      title1: "BNB Balance",
      value1: `${cardValue?.balance} BNB`,
      title2: "BNB Value",
      value2: `${cardValue.txnCount24Hours}`,
      title3: "Token Holdings",
      value3: `${cardValue?.type}`,
    },
    {
      heading: "More Info",
      title1: "Private Name Tags",
      value1: `Powered by AmChain`,
      title2: "Contract Creator",
      value2: `at txn GENESIS_0000...`,
    },
    {
      heading: "Multichain Info",
      title1: "Token Holdings",
      value1: `11 addresses found via`,
    },
  ];

  // Function to handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //get card value
  const getTransactionCardValue = async () => {
    try {
      const response = await axios.get(
        `${constantObject.BaseUrl}/api/address/${ads}`
      );

      setCardValue(response.data);
    } catch (error) {
      console.log("Error fetching card value data:", error);
    }
  };

  // Function to fetch updated transaction data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${
            constantObject.BaseUrl
          }/api/address/${ads}/txns?limit=${rowsPerPage}&page=${page + 1}`
        );

        const { data, totalCount } = response.data;

        setTableData(data);
        setTotalCount(totalCount);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    // Fetch data immediately on mount
    fetchData();

    // Set up interval for fetching updated data
    const intervalId = setInterval(fetchData, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [page, rowsPerPage]);

  useEffect(() => {
    getTransactionCardValue();
  }, []);

  // Function to update exported data when table data changes
  useEffect(() => {
    const startIndex = page * rowsPerPage;
    const endIndex = Math.min(startIndex + rowsPerPage, totalCount);
    const dataForExport = tableData.slice(startIndex, endIndex);

    setExportedData(dataForExport);
  }, [tableData, page, rowsPerPage, totalCount]);

  return (
    <>
      <Navbar />

      {/* loader */}
      {isLoading && <Loader />}

      <div className="card-page-container">
        <header className="address-page-header">
          <h1 className="heading-pending-txn">Contract</h1>
          <span className="address-no">{ads}</span>
        </header>

        <div className="address-button-alignment card-top-button">
          <Button
            variant="outlined"
            size="small"
            style={{
              color: "#081D35",
              backgroundColor: "#e9ecef",
              fontSize: "11px",
              fontWeight: "400",
              lineHeight: "14px",
              padding: "7px 12px",
              borderRadius: "15px",
              border: "1px solid #e9ecef",
              textTransform: "capitalize",
            }}
          >
            BSC: Validator Set
          </Button>
          <Button
            variant="outlined"
            size="small"
            className="white-button-style"
          >
            Source Code
          </Button>
          <Button
            variant="outlined"
            size="small"
            className="white-button-style"
          >
            # BNB Smart Chain
          </Button>
          <Button
            variant="outlined"
            size="small"
            className="white-button-style"
          >
            # System Contracts
          </Button>
        </div>

        <div className="card-container">
          {cardData.map((card, index) => (
            <div className="address-card-container" key={index}>
              <div className="address-card-title">{card.heading}</div>
              <div className="card-key">{card.title1}</div>
              <div
                className="card-value"
                dangerouslySetInnerHTML={{ __html: card.value1 }}
              />

              <div className="card-key">{card.title2}</div>
              <div
                className="card-value"
                dangerouslySetInnerHTML={{ __html: card.value2 }}
              />

              <div className="card-key">{card.title3}</div>
              <div
                className="card-value"
                dangerouslySetInnerHTML={{ __html: card.value3 }}
              />
            </div>
          ))}
        </div>

        <div className="address-button-alignment card-bottom-button">
          <Button
            size="small"
            style={{
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "19px",
              color: "#FFFFFF",
              backgroundColor: "#0784C3",
              textTransform: "capitalize",
              borderRadius: 8,
              padding: "7px 12px",
            }}
          >
            Transactions
          </Button>
          <Button variant="outlined" size="small" className="grey-button-style">
            Internal Transactions
          </Button>
          <Button variant="outlined" size="small" className="grey-button-style">
            Token Transfers (BEP-20)
          </Button>
          <Button variant="outlined" size="small" className="grey-button-style">
            NFT Transfers
          </Button>
          <Button variant="outlined" size="small" className="grey-button-style">
            Contract
          </Button>
          <Button variant="outlined" size="small" className="grey-button-style">
            Events
          </Button>
          <Button variant="outlined" size="small" className="grey-button-style">
            Analytics
          </Button>
          <Button variant="outlined" size="small" className="grey-button-style">
            Multichain Portfolio
          </Button>
        </div>

        <Paper className="paper-style">
          <div style={{ paddingTop: "10px" }}>
            <div className="total-pending-txn">
              Latest {rowsPerPage} from a total of{" "}
              <span style={{ color: "#0784C3" }}>{totalCount}</span>{" "}
              transactions
            </div>
          </div>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Txn Hash</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Method <IoHelpCircleOutline size={22} color="#6C757D" />
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Block</TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#0784C3" }}>
                    Age
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>From</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>To</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Value</TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#0784C3" }}>
                    Txn Fee
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      No data found
                    </TableCell>
                  </TableRow>
                ) : (
                  tableData.map((row) => (
                    <TableRow key={row._id} hover>
                      <TableCell
                        className="cell-style"
                        style={{ cursor: "pointer" }}
                      >
                        <Tooltip title={row.hash} arrow>
                          <span
                            style={{ color: "#0784C3" }}
                            onClick={() => {
                              history.push(`/transactions_detail/${row.hash}`);
                            }}
                          >
                            {row.hash.length > 26
                              ? `${row.hash.substring(0, 26)}...`
                              : row.hash}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell className="cell-style">
                        <Tooltip
                          title={
                            row.tokenData && row.tokenData?.method === 1
                              ? row.tokenData?.method
                              : "Transfer"
                          }
                          arrow
                        >
                          <Button
                            variant="outlined"
                            color="default"
                            size="small"
                            style={{
                              fontSize: "11px",
                              fontWeight: "400",
                              backgroundColor: "#F8F9FA",
                              borderRadius: 8,
                              textTransform: "capitalize",
                              minWidth: "90px",
                            }}
                          >
                            {row.tokenData && row.tokenData?.method === 1
                              ? row.tokenData?.method
                              : "Transfer"}
                          </Button>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className="cell-style"
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          style={{ color: "#0784C3", cursor: "pointer" }}
                          onClick={() => {
                            history.push(`/block_details/${row.blockNumber}`);
                          }}
                        >
                          {row.blockNumber}
                        </span>
                      </TableCell>
                      <TableCell className="cell-style">
                        <Tooltip
                          title={localTimeConvert(row.blockTimestamp)}
                          arrow
                        >
                          <span>{formatTimes(row.blockTimestamp)}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className="cell-style"
                        style={{ color: "#0784C3" }}
                      >
                        {row.tokenData && row.tokenData.from ? (
                          <Tooltip title={row.tokenData?.from} arrow>
                            <span>
                              {row.tokenData?.from.length > 26
                                ? `${row.tokenData?.from.substring(0, 26)}...`
                                : row.tokenData?.from}
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip title={row.from} arrow>
                            <span>
                              {row.from.length > 26
                                ? `${row.from.substring(0, 26)}...`
                                : row.from}
                            </span>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell
                        className="cell-style"
                        style={{ color: "#0784C3" }}
                      >
                        {row.tokenData && row.tokenData.to ? (
                          <Tooltip title={row.tokenData?.to} arrow>
                            <span>
                              {row.tokenData?.to.length > 26
                                ? `${row.tokenData?.to.substring(0, 26)}...`
                                : row.tokenData?.to}
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip title={row.to} arrow>
                            <span>
                              {row.to.length > 26
                                ? `${row.to.substring(0, 26)}...`
                                : row.to}
                            </span>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell className="cell-style">
                        {row.tokenData && row.tokenData.value ? (
                          <Tooltip
                            title={`${row.tokenData?.value} ${row.tokenData?.token}`}
                            arrow
                          >
                            <span>
                              {row.tokenData?.value}&nbsp;{row.tokenData?.token}
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={row.value + " AMC"}
                            arrow
                          >
                            <span>
                              {row.value + " AMC"}
                            </span>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell className="cell-style">{row.txnFee}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="view-all-blocks-cant">
            <Link to="/new_transactions" style={{ textDecoration: "none" }}>
              <span className="view-all-blocks">View All Transaction</span>
              <i
                className="pi pi-arrow-right"
                style={{ fontSize: "10px", color: "#FFFFFF" }}
              ></i>
            </Link>
          </div>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={{ display: "none" }}
          />
        </Paper>

        <div className="add-bottom-content">
          <p className="last-block-content">
            <img src={bulb} alt="logo" height={12.5} width={12.5} />
            Blocks are batches of transactions linked together via cryptographic
            hashes. Any tampering of a block invalidates subsequent blocks as
            their hashes would be changed.
          </p>

          <div className="csv-download">
            [ Download:{" "}
            <CSVLink
              data={exportedData}
              filename={"data.csv"}
              style={{
                color: "#0784C3",
                textDecoration: "none",
                marginLeft: "4px",
              }}
            >
              CSV Export
              <img
                src={csvicon}
                style={{ height: "12px", width: "12px", margin: "0px 4px" }}
                alt="CSV icon"
              />
            </CSVLink>
            ]
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AddressForTxn;
