import axios from "axios";
import constantObject from "../../config";

export const getBlocks = (from, limit) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${constantObject.BaseUrl}/api/block?page=${from}&limit=${limit}`)
      .then(
        (posResponse) => {
          const { data = [] } = posResponse;

          // console.log("Get block data", data);
          resolve(data);
        },
        (errResponse) => {
          reject(errResponse);
        }
      );
  });
};

export const getTxns = (from, limit) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${constantObject.BaseUrl}/api/transaction?page=${from}&limit=${limit}`
      )
      .then(
        (posResponse) => {
          const { data = [] } = posResponse;

          // console.log("Get trnasaction data", data);
          resolve(data);
        },
        (errResponse) => {
          reject(errResponse);
        }
      );
  });
};

export const getTxnsDetail = (txnHash) => {
  return new Promise((resolve, reject) => {
    axios.get(`${constantObject.BaseUrl}/api/transaction/${txnHash}`).then(
      (posResponse) => {
        const { data = [] } = posResponse;
        // console.log("Get txn detail data", data);
        resolve(data);
      },
      (errResponse) => {
        reject(errResponse);
      }
    );
  });
};

export const getBlockDetail = (blockNumber) => {
  return new Promise((resolve, reject) => {
    axios.get(`${constantObject.BaseUrl}/api/block/${blockNumber}`).then(
      (posResponse) => {
        const { data = [] } = posResponse;
        // console.log("Get block detail data", data);
        resolve(data);
      },
      (errResponse) => {
        reject(errResponse);
      }
    );
  });
};

export const getAddress = (hash) => {
  return new Promise((resolve, reject) => {
    axios.get(`${constantObject.BaseUrl}/api/address/${hash}`).then(
      (posResponse) => {
        const { data = [] } = posResponse;
        // console.log("Get address data", data);
        resolve(data);
      },
      (errResponse) => {
        reject(errResponse);
      }
    );
  });
};

export const getAddressTxns = (hash, start, limit) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${constantObject.BaseUrl}/api/address/${hash}/txns?page=${start}&limit=${limit}`
      )
      .then(
        (posResponse) => {
          const { data = [] } = posResponse;
          // console.log("Get getAddressTxns data", data);
          resolve(data);
        },
        (errResponse) => {
          reject(errResponse);
        }
      );
  });
};

export const getBlockTxns = (blockNum, start, limit) => {
  return new Promise((resolve, reject) => {
    // console.log("LImut ", limit);
    axios
      .get(
        `${constantObject.BaseUrl}/api/block/${blockNum}/txns?page=${start}&limit=${limit}`
      )
      .then(
        (posResponse) => {
          const { data = [] } = posResponse;
          // console.log("Get getblockTxns data", data);
          resolve(data);
        },
        (errResponse) => {
          reject(errResponse);
        }
      );
  });
};

export const getSearchResult = (keyword) => {
  return new Promise((resolve, reject) => {
    axios.get(`${constantObject.BaseUrl}/api/search?id=${keyword}`).then(
      (posResponse) => {
        const { data = [] } = posResponse;
        // console.log("Get getblockTxns data", data);
        resolve(data);
      },
      (errResponse) => {
        reject(errResponse);
      }
    );
  });
};
