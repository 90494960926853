import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../assets/images/nav-logo3.png";
import { FaBars } from "react-icons/fa";


const NavBar = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const isNavLinkActive = (path) => {
    return location.pathname.includes(path);
  };

  return (
    <>
      <Navbar className="navbar">
        <div className="left">
          <NavLink to="/">
            <img src={logo} alt="Logo" className="logo" />
          </NavLink>
        </div>

        {/* Toggle button for smaller screens */}
        <div className="navbar-toggle" onClick={toggleMenu}>
          <button>
            <FaBars size={20} color="#000" />
          </button>
        </div>

        <div className={`right ${isMenuOpen ? "show" : ""}`}>
          <div className="navbar-link">
            <NavLink exact to="/" activeClassName={isNavLinkActive("/") ? "active-link" : ""}>
              Home
            </NavLink>
          </div>

          <div className="navbar-link">
            <NavLink to="/new_block_page" activeClassName={isNavLinkActive("/new_block_page") ? "active-link" : ""}>
              View Blocks
            </NavLink>
          </div>

          <div className="navbar-link">
            <NavLink to="/new_transactions" activeClassName={isNavLinkActive("/new_transactions") ? "active-link" : ""}>
              Transactions
            </NavLink>
          </div>

          {/* <div className="navbar-link">
            <NavLink to="/pending_transactions" activeClassName={isNavLinkActive("/pending_transactions") ? "active-link" : ""}>
              Pending Transactions
            </NavLink>
          </div>

          <div className="navbar-link">
            <NavLink to="#" activeClassName={isNavLinkActive("#") ? "active-link" : ""}>
              Verify Contracts
            </NavLink>
          </div>

          <div className="navbar-link">
            <NavLink to="#1" activeClassName={isNavLinkActive("#1") ? "active-link" : ""}>
              Validators
            </NavLink>
          </div>

          <div className="navbar-link">
            <NavLink to="#2" activeClassName={isNavLinkActive("#2") ? "active-link" : ""}>
              Help
            </NavLink>
          </div> */}
        </div>
      </Navbar>
    </>
  );
}

export default NavBar;
