/**
 * Convert seconds to days , hrs , min or seconds and return age
 * @param {*} seconds
 * @returns
 */
export const secondsToDhms = (seconds) => {
  seconds = Number(seconds);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days ") : "";
  const hDisplay = h > 0 ? h + (h === 1 ? " hr " : " hrs ") : "";
  const mDisplay = m > 0 ? m + (m === 1 ? " min " : " mins ") : "";
  const sDisplay = s > 0 ? s + (s === 1 ? " second" : " secs") : "";
  if (dDisplay !== "") {
    return dDisplay + " ago";
  } else if (hDisplay !== "") {
    return hDisplay + " ago";
  } else if (mDisplay !== "") {
    return mDisplay + " ago";
  }
  return sDisplay + " ago";
};

export const formatTimeStamp = (timestamp) => {
  const formattedDate = new Date(timestamp);
  return formattedDate.toUTCString();
};

export const localTimeConvert = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const formattedDate = date.toLocaleString();
  return formattedDate;
};

export const formatTimes = (timestamp) => {
  const secondsPassed = Math.floor((Date.now() - timestamp * 1000) / 1000);

  const minute = 60;
  const hour = 60 * minute;
  const day = 24 * hour;
  const month = 30 * day;
  const year = 365 * day;

  if (secondsPassed > year) {
    const years = Math.floor(secondsPassed / year);
    return `${years} year${years > 1 ? "s" : ""} ago`;
  } else if (secondsPassed > month) {
    const months = Math.floor(secondsPassed / month);
    return `${months} month${months > 1 ? "s" : ""} ago`;
  } else if (secondsPassed > day) {
    const days = Math.floor(secondsPassed / day);
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (secondsPassed > hour) {
    const hours = Math.floor(secondsPassed / hour);
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (secondsPassed > minute) {
    const minutes = Math.floor(secondsPassed / minute);
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    return `${secondsPassed} second${secondsPassed > 1 ? "s" : ""} ago`;
  }
};
