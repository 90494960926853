import React, { useEffect, useState } from "react";
import NavBar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { Tooltip } from "@mui/material";
import { FaRegClock } from "react-icons/fa";
import { Paper, Button } from "@material-ui/core";
import { IoHelpCircleOutline } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import constantObject from "../../config";
import Bulb from "../../assets/images/Vector.png";
import { formatTimeStamp } from "../../common/Functions";
import "./index.scss";
import "remixicon/fonts/remixicon.css";
import Loader from "../../components/Loader";

const BlockDetail = () => {
  const [blockDetailData, setblockDetailData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showMore, setShowMore] = useState(false);

  const { blocknumber } = useParams();

  useEffect(() => {
    getBlockDetail();
  }, []);

  // get Transactions table data
  const getBlockDetail = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `${constantObject.BaseUrl}/api/block/${blocknumber}`
      );

      const transactions_detail = response.data;

      setblockDetailData(transactions_detail);
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <NavBar />

      {/* loader */}
      {isLoading && <Loader />}

      <div className="card-page-container">
        <header className="block-details-header">
          <h1 className="heading-blocks">Blocks</h1>
          <span className="block-detail-id">#{blocknumber}</span>
        </header>

        <div className="button-container">
          {/* <Button
            variant="outlined"
            size="small"
            style={{
              color: "#FFFFFF",
              backgroundColor: "#0784C3",
              textTransform: "capitalize",
              borderRadius: 8,
              marginRight: "10px",
              border: "1px solid #e9ecef",
            }}
          >
            Overview
          </Button>
          <Button
            variant="outlined"
            size="small"
            style={{
              color: "#081D35",
              textTransform: "capitalize",
              borderRadius: 8,
              backgroundColor: "#e9ecef",
              border: "1px solid #e9ecef",
            }}
          >
            Comments
          </Button> */}
        </div>

        <Paper className="paper-style">
          <div className="block-container">
            <div className="row mb-3">
              <div className="col-md-4">
                <div className="block-style">
                  <IoHelpCircleOutline size={22} color="#6C757D" />
                  <span className="block-height-style">Block Height:</span>
                </div>
              </div>
              <div className="col-md-8">
                <div className="block-style">
                  <span className="block-real-data">{blocknumber}</span>

                  {/* <Tooltip title="View previous block" arrow>
                    <Button
                      variant="outlined"
                      size="small"
                      style={{
                        backgroundColor: "#e9ecef",
                        borderRadius: 6,
                        minWidth: "20px",
                        border: "1px solid #e9ecef",
                        marginRight: "6px",
                      }}
                    >
                      <i
                        className="ri-arrow-left-s-line"
                        style={{ fontWeight: "600" }}
                      ></i>
                    </Button>
                  </Tooltip>
                  <Tooltip title="View next block" arrow>
                    <Button
                      variant="outlined"
                      size="small"
                      style={{
                        backgroundColor: "#e9ecef",
                        borderRadius: 6,
                        minWidth: "20px",
                        border: "1px solid #e9ecef",
                      }}
                    >
                      <i
                        className="ri-arrow-right-s-line"
                        style={{ fontWeight: "600" }}
                      ></i>
                    </Button>
                  </Tooltip> */}
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-4">
                <div className="block-style">
                  <IoHelpCircleOutline size={22} color="#6C757D" />
                  <span className="block-height-style">Timestamp:</span>
                </div>
              </div>
              <div className="col-md-8">
                <div className="block-style">
                  <FaRegClock size={16} style={{ marginLeft: "6px" }} />
                  <span className="block-real-data">
                    ({formatTimeStamp(blockDetailData.timestamp)})
                  </span>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-4">
                <div className="block-style">
                  <IoHelpCircleOutline size={22} color="#6C757D" />
                  <span className="block-height-style">Transactions:</span>
                </div>
              </div>
              <div className="col-md-8">
                <div className="block-style">
                  <div className="block-real-data">
                    <Tooltip title="Click to view Transactions" arrow>
                      <Link
                        to={`/txn_for_block/${blockDetailData.number}`}
                        className="block-transaction-data-count"
                      >
                        {blockDetailData.txns} transactions
                      </Link>
                    </Tooltip>
                    <span style={{ marginRight: "6px" }}>and</span>
                    <Link to="#1" className="block-transaction-data-count">
                      0 contract internal transactions
                    </Link>
                    <span>in this block</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="block-container">
            <div className="row mb-3">
              <div className="col-md-4">
                <div className="block-style">
                  <IoHelpCircleOutline size={22} color="#6C757D" />
                  <span className="block-height-style">Block Reward:</span>
                </div>
              </div>
              <div className="col-md-8">
                <div className="block-style">
                  <span className="block-real-data">
                    {blockDetailData.reward + " AMC"}
                  </span>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-4">
                <div className="block-style">
                  <IoHelpCircleOutline size={22} color="#6C757D" />
                  <span className="block-height-style">Difficulty:</span>
                </div>
              </div>
              <div className="col-md-8">
                <div className="block-style">
                  <span className="block-real-data">
                    {blockDetailData.difficulty}
                  </span>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-4">
                <div className="block-style">
                  <IoHelpCircleOutline size={22} color="#6C757D" />
                  <span className="block-height-style">Total Difficulty:</span>
                </div>
              </div>
              <div className="col-md-8">
                <div className="block-style">
                  <span className="block-real-data">
                    {blockDetailData.totalDifficulty}
                  </span>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-4">
                <div className="block-style">
                  <IoHelpCircleOutline size={22} color="#6C757D" />
                  <span className="block-height-style">Size:</span>
                </div>
              </div>
              <div className="col-md-8">
                <div className="block-style">
                  <span className="block-real-data">
                    {blockDetailData.size} bytes
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="block-container">
            <div className="row mb-3">
              <div className="col-md-4">
                <div className="block-style">
                  <IoHelpCircleOutline size={22} color="#6C757D" />
                  <span className="block-height-style">Gas Used:</span>
                </div>
              </div>
              <div className="col-md-8">
                <div className="block-style">
                  <div className="gas-used-block">
                    <span className="block-real-data">
                      {blockDetailData.gasUsed}
                    </span>
                    {/* <span className="gas-percent-block">(12.35%)</span> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-4">
                <div className="block-style">
                  <IoHelpCircleOutline size={22} color="#6C757D" />
                  <span className="block-height-style">Gas Limit:</span>
                </div>
              </div>
              <div className="col-md-8">
                <div className="block-style">
                  <span className="block-real-data">
                    {blockDetailData.gasLimit}
                  </span>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-4">
                <div className="block-style">
                  <IoHelpCircleOutline size={22} color="#6C757D" />
                  <span className="block-height-style">Burnt Fees:</span>
                </div>
              </div>
              <div className="col-md-8">
                <div className="block-style">
                  <span className="block-real-data">
                    {/* 🔥0.007803225696528699 HEWE */}
                    N/A
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="block-style">
                  <IoHelpCircleOutline size={22} color="#6C757D" />
                  <span className="block-height-style">Extra Data:</span>
                </div>
              </div>
              <div className="col-md-8">
                <div className="block-style">
                  <div className="extra-block-data">
                    <p>{blockDetailData.extraData}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Paper>

        {/* <Paper className="paper-style" style={{ marginTop: "1rem" }}>
          <div className="show-more-block-data row">
            <span className="more-details-text col-md-4">More Details:</span>
            <span
              className="show-more-details-data col-md-8"
              onClick={() => {
                setShowMore(!showMore);
              }}
            >
              {showMore ? "- Click to hide" : "+ Click to show more"}
            </span>
            {showMore && (
              <div>
                <p style={{ marginBottom: "0px", paddingLeft: "15px" }}>
                  Additional details...
                </p>
              </div>
            )}
          </div>
        </Paper> */}

        <div className="bottom-content">
          <p className="last-block-content">
            <img src={Bulb} alt="Bulb logo" height={12.5} width={12.5} />
            Blocks are batches of transactions linked together via cryptographic
            hashes. Any tampering of a block invalidates subsequent blocks as
            their hashes would be changed.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default BlockDetail;
