import React from "react";
import {
  Container,
  Row,
  Col,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Navbar from "../../components/Navbar";
import { getBlockDetail } from "../../common/Api";
import ThreeDots from "../../assets/loaders/three-dots.svg";
import { constantObject } from "../../common/Constants";
import OverlayQues from "../../components/OverlayQues";
import "./index.scss";

import { secondsToDhms, formatTimeStamp } from "../../common/Functions";
class BlockDetailPage extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {},
      isLoading: true,
      blockKeys: [
        "Block Number:",
        "Timestamp:",
        "Transactions:",
        "Mined By:",
        "Block Reward:",
        "Uncles:",
        "Difficulty:",
        "Total Difficulty:",
        "Size:",
        "Gas Used:",
        "Gas Limit:",
        "Extra Data:",
        "Hash:",
        "Parent Hash:",
        "State Root:",
        "Nonce:",
        "Age:",
      ],
    };
  }

  componentDidMount() {
    this.init();
  }

  /*
  Call the api and get block details
   * 
  */
  init = async () => {
    try {
      const txnDetail = await getBlockDetail(this.props.match.params.blockid);
      // const txnDetail = {
      //   number: 19144,
      //   timestamp: "2021-03-29T11:11:29.000Z",
      //   txns: 0,
      //   miner: "0xfe3b557e8fb62b89f4916b721be55ceb828dbd73",
      //   miningReward: 2,
      //   uncles: 0,
      //   difficulty: 10000,
      //   totalDifficulty: 122200,
      //   size: 500,
      //   gasUsed: 12344,
      //   gasLimit: 44444,
      //   extraData: "0x",
      //   hash: "0x545a3b165e795a6e75a7d574fe4aa53deba2dbf0cf5cca25a4964f3d1c1a7578",
      //   parentHash:
      //     "0x545a3b165e795a6e75a7d574fe4aa53deba2dbf0cf5cca25a4964f3d1c1a7578",
      //   stateRoot: "543534543",
      //   nonce: 344242,
      //   age: 33233,
      //   extraReward: 435,
      // };
      this.setState({ data: txnDetail, isLoading: false });
    } catch (e) {}
  };

  /* @param parentHash to get data for
   * Get and show data of parent hash of current block
   */
  getParentHashData = async (parentHash) => {
    try {
      const txnDetail = await getBlockDetail(parentHash);
      this.setState({ data: txnDetail });
    } catch (e) {}
  };

  render() {
    return (
      <div>
        {" "}
        <Navbar></Navbar>
        <Container fluid style={{ marginLeft: "unset" }}>
          {this.state.isLoading ? (
            <Row>
              <Col>
                <img src={ThreeDots} style={{ marginTop: "100px" }}></img>
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col>
                  <h1
                    style={{
                      paddingTop: "20px",
                      fontSize: "1.3125rem",
                      color: "#77838f",
                      fontWeight: 400,
                      marginTop: "10px",
                      marginBottom: "30px",
                      marginLeft: "10px",
                    }}
                  >
                    Block Details{" "}
                  </h1>
                </Col>
              </Row>
              <Row>
                <Col style={{ overflowX: "hidden" }}>
                  <Table
                    size="md"
                    className="block-detail"
                    style={{ margin: "0px 10px", overflowX: "hidden" }}
                  >
                    <tbody>
                      <tr style={{ marginLeft: "10px" }}>
                        <td className="table-head">
                          <OverlayQues
                            text={constantObject.BlockNumberDescription}
                          ></OverlayQues>
                          <span>{this.state.blockKeys[0]}</span>
                        </td>
                        <td>
                          <span style={{ fontWeight: "bold" }}>
                            {this.state.data.number}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="block-detail-cells table-head">
                          <span>
                            <OverlayQues
                              text={constantObject.TimeStampDescription}
                            ></OverlayQues>
                            {this.state.blockKeys[1]}
                          </span>
                        </td>
                        <td className="block-detail-cells">
                          <span>
                            <i className="fa fa-clock-o"></i>{" "}
                            {secondsToDhms(this.state.data.age)}
                            {" ("}
                            {formatTimeStamp(this.state.data.timestamp)}
                            {")"}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="table-head">
                          <OverlayQues
                            text={constantObject.TransactionNumber}
                          ></OverlayQues>{" "}
                          {this.state.blockKeys[2]}
                        </td>
                        {this.state.data.txns === 0 ? (
                          <td> {this.state.data.txns} </td>
                        ) : (
                          <td>
                            <span
                              className="app-links"
                              onClick={() =>
                                this.props.history.push(
                                  `/txns/${this.state.data.number}`
                                )
                              }
                            >
                              {" "}
                              {this.state.data.txns}{" "}
                            </span>
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td className="block-detail-cells table-head">
                          <OverlayQues
                            text={constantObject.Miner}
                          ></OverlayQues>{" "}
                          {this.state.blockKeys[3]}
                        </td>
                        <td className="block-detail-cells">
                          <span
                            className="app-links"
                            onClick={() =>
                              this.props.history.push(
                                `/address/${this.state.data.miner}`
                              )
                            }
                          >
                            {this.state.data.miner}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="table-head">
                          <OverlayQues
                            text={constantObject.Reward}
                          ></OverlayQues>{" "}
                          {this.state.blockKeys[4]}
                        </td>
                        <td>
                          <span>
                            {this.state.data.reward}{" "}
                            {constantObject.RewardUnits}
                            {" ( "}
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="button-tooltip-2">
                                  {" "}
                                  {"Static block reward"}
                                </Tooltip>
                              }
                            >
                              {({ ref, ...triggerHandler }) => (
                                <span ref={ref} {...triggerHandler}>
                                  {this.state.data.miningReward}
                                </span>
                              )}
                            </OverlayTrigger>
                            {" + "}
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="button-tooltip-2">
                                  {"Txn Fees"}
                                </Tooltip>
                              }
                            >
                              {({ ref, ...triggerHandler }) => (
                                <span ref={ref} {...triggerHandler}>
                                  {this.state.data.extraReward}
                                </span>
                              )}
                            </OverlayTrigger>
                            {" )"}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="block-detail-cells table-head">
                          <OverlayQues
                            text={constantObject.Uncles}
                          ></OverlayQues>{" "}
                          {this.state.blockKeys[5]}
                        </td>
                        <td className="block-detail-cells">
                          {this.state.data.uncles}
                        </td>
                      </tr>
                      <tr>
                        <td className="table-head ">
                          <OverlayQues
                            text={constantObject.Difficulty}
                          ></OverlayQues>{" "}
                          {this.state.blockKeys[6]}
                        </td>
                        <td>{this.state.data.difficulty}</td>
                      </tr>
                      <tr>
                        <td className="block-detail-cells table-head">
                          {" "}
                          <OverlayQues
                            text={constantObject.TotalDifficulty}
                          ></OverlayQues>{" "}
                          {this.state.blockKeys[7]}
                        </td>
                        <td className="block-detail-cells">
                          {this.state.data.totalDifficulty}
                        </td>
                      </tr>
                      <tr>
                        <td className="table-head">
                          <OverlayQues
                            text={constantObject.BlockSize}
                          ></OverlayQues>{" "}
                          {this.state.blockKeys[8]}
                        </td>
                        <td>{this.state.data.size}</td>
                      </tr>
                      <tr>
                        <td className="block-detail-cells table-head">
                          <OverlayQues
                            text={constantObject.GasUsed}
                          ></OverlayQues>{" "}
                          {this.state.blockKeys[9]}
                        </td>
                        <td className="block-detail-cells">
                          {this.state.data.gasUsed}
                        </td>
                      </tr>
                      <tr>
                        <td className="table-head">
                          <OverlayQues
                            text={constantObject.GasLimit}
                          ></OverlayQues>{" "}
                          {this.state.blockKeys[10]}
                        </td>
                        <td>{this.state.data.gasLimit}</td>
                      </tr>
                      <tr>
                        <td className="block-detail-cells table-head">
                          <OverlayQues
                            text={constantObject.ExtraData}
                          ></OverlayQues>{" "}
                          {this.state.blockKeys[11]}
                        </td>
                        <td className="block-detail-cells">
                          {this.state.data.extraData}
                        </td>
                      </tr>
                      <tr>
                        <td className="table-head">
                          <OverlayQues text={constantObject.Hash}></OverlayQues>{" "}
                          {this.state.blockKeys[12]}
                        </td>
                        <td>{this.state.data.hash}</td>
                      </tr>
                      <tr>
                        <td className="block-detail-cells table-head">
                          <OverlayQues
                            text={constantObject.ParentHash}
                          ></OverlayQues>{" "}
                          {this.state.blockKeys[13]}
                        </td>
                        <td className="block-detail-cells">
                          <span
                            className="app-links"
                            onClick={() => {
                              this.getParentHashData(
                                this.state.data.parentHash
                              );
                            }}
                          >
                            {this.state.data.parentHash}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="table-head">
                          {" "}
                          <OverlayQues
                            text={constantObject.StateRoot}
                          ></OverlayQues>{" "}
                          {this.state.blockKeys[14]}
                        </td>
                        <td>{this.state.data.stateRoot}</td>
                      </tr>
                      <tr>
                        <td className="block-detail-cells table-head">
                          {" "}
                          <OverlayQues
                            text={constantObject.Nonce}
                          ></OverlayQues>{" "}
                          {this.state.blockKeys[15]}
                        </td>
                        <td className="block-detail-cells">
                          {this.state.data.nonce}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    );
  }
}

export default BlockDetailPage;
