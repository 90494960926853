import React from "react";
import {
  Container,
  Table,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { getTxnsDetail } from "../../common/Api";
import { formatTimeStamp, secondsToDhms } from "../../common/Functions";
import { constantObject } from "../../common/Constants";
import OverlayQues from "../../components/OverlayQues";
import ThreeDots from "../../assets/loaders/three-dots.svg";

class Transactions extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {},
      isLoading: true,
      isToAddressCopied: false,
      isHashCopied: false,
      isFromAddressCopied: false,
    };
  }

  componentDidMount() {
    this.init();
  }

  /**
   * Calls the transaction detail api and sets the result in state.
   */
  init = async () => {
    try {
      const txnDetail = await getTxnsDetail(this.props.match.params.txid);
      this.setState({ data: txnDetail, isLoading: false });
    } catch (e) {}
  };

  render() {
    const labelArr = [
      "Transaction Hash:",
      "Status:",
      "Block:",
      "Timestamp:",
      "From:",
      "To:",
      "Value:",
      "Transaction Fee:",
      "Gas Price",
      "Gas Limit:",
      "Nonce:",
      "Input Data:",
      "Age:",
    ];
    return (
      <div>
        {" "}
        <Navbar />

        <Container fluid style={{ marginLeft: "unset" }}>
          {this.state.isLoading ? (
            <Row>
              <Col>
                <img src={ThreeDots} style={{ marginTop: "100px" }}></img>
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col>
                  <h1
                    style={{
                      paddingTop: "20px",
                      fontSize: "1.3125rem",
                      color: "#77838f",
                      fontWeight: 400,
                      marginTop: "10px",
                      marginBottom: "30px",
                      marginLeft: "10px",
                    }}
                  >
                    Transaction Details{" "}
                  </h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Table
                    size="md"
                    className="block-detail"
                    style={{ margin: "0px 10px" }}
                  >
                    <tbody>
                      <tr style={{ marginLeft: "10px", width: "100%" }}>
                        <td className="table-head " style={{ width: "30%" }}>
                          <span>
                            <OverlayQues
                              text={constantObject.TransactionHash}
                            />
                            {labelArr[0]}
                          </span>{" "}
                        </td>
                        <td>
                          <span>{this.state.data.hash} </span>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="button-tooltip-2">
                                {"Copy Transaction Hash to clipboard"}
                              </Tooltip>
                            }
                          >
                            {({ ref, ...triggerHandler }) =>
                              this.state.isHashCopied ? (
                                <i
                                  ref={ref}
                                  {...triggerHandler}
                                  className="fa fa-check-circle mr-1 "
                                  style={{ color: "#77838f" }}
                                >
                                  {" "}
                                  Copied
                                </i>
                              ) : (
                                <i
                                  ref={ref}
                                  {...triggerHandler}
                                  className="fa fa-copy "
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      this.state.data.hash
                                    );
                                    this.setState({ isHashCopied: true });
                                    setTimeout(() => {
                                      this.setState({ isHashCopied: false });
                                    }, 1000);
                                  }}
                                  style={{
                                    marginLeft: "5px",
                                    color: "#77838f",
                                  }}
                                ></i>
                              )
                            }
                          </OverlayTrigger>
                        </td>
                      </tr>
                      <tr>
                        <td className="table-head">
                          <OverlayQues text={constantObject.Status} />
                          {labelArr[1]}
                        </td>
                        <td>
                          {this.state.data.status ? (
                            this.state.data.status === 1 ? (
                              <span
                                style={{
                                  color: "#00c9a7",
                                  backgroundColor: "#c0fcf2",
                                  padding: ".4rem .7rem",
                                  borderRadius: ".35rem",
                                }}
                              >
                                <i className="fa fa-check-circle mr-1 "></i>
                                Success
                              </span>
                            ) : (
                              <span
                                style={{
                                  color: "#de4437",
                                  backgroundColor: "rgba(222,68,55,.1)",
                                  padding: ".4rem .7rem",
                                  borderRadius: ".35rem",
                                }}
                              >
                                <i className="fa fa-times-circle mr-1 "></i>Fail
                              </span>
                            )
                          ) : (
                            <span
                              style={{
                                color: "#77838f",
                                backgroundColor: "rgba(119,131,143,.1)",
                                padding: ".4rem .7rem",
                                borderRadius: ".35rem",
                              }}
                            >
                              <i className="fa fa-dot-circle-o mr-1 "></i>
                              Pending
                            </span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="table-head">
                          <OverlayQues
                            text={constantObject.BlockNumberDescription}
                          />
                          {labelArr[2]}
                        </td>
                        <td>
                          <span
                            className="app-links"
                            onClick={() =>
                              this.props.history.push(
                                `/block/${this.state.data.blockNumber}`
                              )
                            }
                          >
                            {this.state.data.blockNumber}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="table-head">
                          <OverlayQues
                            text={constantObject.TimeStampDescription}
                          />
                          {labelArr[3]}
                        </td>
                        <td>
                          {" "}
                          {this.state.data.status ? (
                            <span>
                              <i className="fa fa-clock-o" style={{}}></i>{" "}
                              {secondsToDhms(this.state.data.age)}
                              {" ("}
                              {formatTimeStamp(this.state.data.timestamp)}
                              {")"}
                            </span>
                          ) : (
                            <span>
                              <i className="fa fa-clock-o" style={{}}></i>{" "}
                            </span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="table-head">
                          <OverlayQues text={constantObject.FromAddress} />
                          {labelArr[4]}
                        </td>
                        <td>
                          <span
                            className="app-links"
                            onClick={() =>
                              this.props.history.push(
                                `/address/${this.state.data.from}`
                              )
                            }
                          >
                            {this.state.data.from}
                          </span>{" "}
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="button-tooltip-2">
                                {"Copy From Address to clipboard"}
                              </Tooltip>
                            }
                          >
                            {({ ref, ...triggerHandler }) =>
                              this.state.isFromAddressCopied ? (
                                <i
                                  ref={ref}
                                  {...triggerHandler}
                                  className="fa fa-check-circle mr-1 "
                                  style={{ color: "#77838f" }}
                                >
                                  {" "}
                                  Copied
                                </i>
                              ) : (
                                <i
                                  ref={ref}
                                  {...triggerHandler}
                                  className="fa fa-copy "
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      this.state.data.from
                                    );
                                    this.setState({
                                      isFromAddressCopied: true,
                                    });
                                    setTimeout(() => {
                                      this.setState({
                                        isFromAddressCopied: false,
                                      });
                                    }, 1000);
                                  }}
                                  style={{
                                    marginLeft: "5px",
                                    color: "#77838f",
                                  }}
                                ></i>
                              )
                            }
                          </OverlayTrigger>
                        </td>
                      </tr>
                      <tr>
                        <td className="table-head">
                          <OverlayQues text={constantObject.ToAddress} />
                          {labelArr[5]}
                        </td>
                        {this.state.data.type === "CONTRACT_CREATION" ? (
                          <td>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="button-tooltip-2">
                                  {this.state.data.to}
                                </Tooltip>
                              }
                            >
                              {({ ref, ...triggerHandler }) => (
                                <span
                                  ref={ref}
                                  {...triggerHandler}
                                  className="app-links"
                                  onClick={() =>
                                    this.props.history.push(
                                      `/address/${this.state.data.to}`
                                    )
                                  }
                                >
                                  <i
                                    className="fa fa-newspaper-o mr-1 "
                                    style={{
                                      fontColor: "#77838f",
                                      fontWeight: "normal",
                                    }}
                                  ></i>
                                  {"CONTRACT_CREATION"}
                                </span>
                              )}
                            </OverlayTrigger>{" "}
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="button-tooltip-2">
                                  {"Copy To Address to clipboard"}
                                </Tooltip>
                              }
                            >
                              {({ ref, ...triggerHandler }) =>
                                this.state.isToAddressCopied ? (
                                  <i
                                    ref={ref}
                                    {...triggerHandler}
                                    className="fa fa-check-circle mr-1 "
                                    style={{ color: "#77838f" }}
                                  >
                                    {" "}
                                    Copied
                                  </i>
                                ) : (
                                  <i
                                    ref={ref}
                                    {...triggerHandler}
                                    className="fa fa-copy "
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        this.state.data.to
                                      );
                                      this.setState({
                                        isToAddressCopied: true,
                                      });
                                      setTimeout(() => {
                                        this.setState({
                                          isToAddressCopied: false,
                                        });
                                      }, 1000);
                                    }}
                                    style={{
                                      marginLeft: "5px",
                                      color: "#77838f",
                                    }}
                                  ></i>
                                )
                              }
                            </OverlayTrigger>
                          </td>
                        ) : (
                          <td>
                            <span
                              className="app-links"
                              onClick={() =>
                                this.props.history.push(
                                  `/address/${this.state.data.to}`
                                )
                              }
                            >
                              {this.state.data.to}
                            </span>{" "}
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="button-tooltip-2">
                                  {"Copy To Address to clipboard"}
                                </Tooltip>
                              }
                            >
                              {({ ref, ...triggerHandler }) =>
                                this.state.isToAddressCopied ? (
                                  <i
                                    ref={ref}
                                    {...triggerHandler}
                                    className="fa fa-check-circle mr-1 "
                                    style={{ color: "#77838f" }}
                                  >
                                    {" "}
                                    Copied
                                  </i>
                                ) : (
                                  <i
                                    ref={ref}
                                    {...triggerHandler}
                                    className="fa fa-copy "
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        this.state.data.to
                                      );
                                      this.setState({
                                        isToAddressCopied: true,
                                      });
                                      setTimeout(() => {
                                        this.setState({
                                          isToAddressCopied: false,
                                        });
                                      }, 1000);
                                    }}
                                    style={{
                                      marginLeft: "5px",
                                      color: "#77838f",
                                    }}
                                  ></i>
                                )
                              }
                            </OverlayTrigger>
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td className="table-head">
                          <OverlayQues text={constantObject.Value} />
                          {labelArr[6]}
                        </td>
                        <td>{this.state.data.value}</td>
                      </tr>
                      <tr>
                        <td className="table-head">
                          <OverlayQues text={constantObject.Fee} />
                          {labelArr[7]}
                        </td>
                        <td>
                          {this.state.data.txnFee} {constantObject.RewardUnits}
                        </td>
                      </tr>
                      <tr>
                        <td className="table-head">
                          <OverlayQues text={constantObject.GasPrice} />
                          {labelArr[8]}
                        </td>
                        <td>
                          {this.state.data.gasPrice}{" "}
                          {constantObject.GasPriceUnits}
                        </td>
                      </tr>
                      <tr>
                        <td className="table-head">
                          <OverlayQues text={constantObject.GasLimit} />
                          {labelArr[9]}
                        </td>
                        <td>{this.state.data.gasLimit}</td>
                      </tr>
                      <tr>
                        <td className="table-head">
                          <OverlayQues text={constantObject.Nonce} />
                          {labelArr[10]}
                        </td>
                        <td>{this.state.data.nonce}</td>
                      </tr>
                      <tr>
                        <td className="table-head">
                          <OverlayQues text={constantObject.InputData} />
                          {labelArr[11]}
                        </td>
                        <td style={{ wordBreak: "break-word" }}>
                          {this.state.data.inputData}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </>
          )}
        </Container>

        <Footer />
      </div>
    );
  }
}

export default Transactions;
