import React from "react";
import { Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import { secondsToDhms, formatTimeStamp } from "../common/Functions";
import { useHistory } from "react-router-dom";
import { constantObject, txnType } from "../common/Constants";
function Transactions(props) {
  const history = useHistory();
  const txnsHead = [
    "Txn Hash",
    "Block",
    "Age",
    "From",
    "To",
    "Value",
    "Txn Fee",
  ];
  return (
    <Table striped responsive>
      <thead>
        <tr>
          {txnsHead.map((heading, index) => {
            return (
              <th style={{ padding: "10px 20px" }} key={index}>
                {heading}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {props.txnsArr.map((currentBlock, index) => {
          return (
            <tr key={index}>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip-2">{currentBlock.hash}</Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <td ref={ref} {...triggerHandler} className="hash-tag-txn">
                    <span
                      className="app-links"
                      onClick={() => history.push(`/txs/${currentBlock.hash}`)}
                    >
                      {currentBlock.hash}
                    </span>
                  </td>
                )}
              </OverlayTrigger>
              <td>
                <span
                  className="app-links"
                  onClick={() =>
                    history.push(`/block/${currentBlock.blockNumber}`)
                  }
                >
                  {currentBlock.blockNumber}
                </span>
              </td>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip-2">
                    {formatTimeStamp(currentBlock.timestamp)}
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <td>
                    <span ref={ref} {...triggerHandler}>
                      {secondsToDhms(currentBlock.age)}
                    </span>
                  </td>
                )}
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip-2">
                    {currentBlock.fromAddress}
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <td ref={ref} {...triggerHandler} className="hash-tag-txn">
                    <span
                      className={
                        props.hash !== currentBlock.fromAddress
                          ? "app-links"
                          : ""
                      }
                      onClick={() => {
                        if (props.hash !== currentBlock.fromAddress) {
                          if (props.initFunction) {
                            props.initFunction(currentBlock.fromAddress);
                          }
                          history.push(`/address/${currentBlock.fromAddress}`);
                        }
                      }}
                    >
                      {currentBlock.fromAddress}
                    </span>
                  </td>
                )}
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip-2">
                    {currentBlock.toAddress}
                  </Tooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <td ref={ref} {...triggerHandler} className="hash-tag-txn">
                    {currentBlock.type === txnType.CONTRACT_CREATION ? (
                      <>
                        <i
                          className="fa fa-newspaper-o mr-1 "
                          style={{ fontColor: "#6c757e", fontWeight: "normal" }}
                        ></i>
                        <span
                          className={
                            props.hash !== currentBlock.toAddress
                              ? "app-links"
                              : ""
                          }
                          onClick={() => {
                            if (props.hash !== currentBlock.toAddress) {
                              if (props.initFunction) {
                                props.initFunction(currentBlock.toAddress);
                              }
                              history.push(
                                `/address/${currentBlock.toAddress}`
                              );
                            }
                          }}
                        >
                          CONTRACT CREATION
                        </span>
                      </>
                    ) : (
                      <>
                        {currentBlock.type === txnType.CONTRACT_CALL && (
                          <i
                            className="fa fa-file-alt"
                            style={{
                              fontColor: "#77838f",
                              fontWeight: "normal",
                            }}
                          ></i>
                        )}
                        <span
                          className={
                            props.hash !== currentBlock.toAddress
                              ? "app-links"
                              : ""
                          }
                          onClick={() => {
                            if (props.hash !== currentBlock.toAddress) {
                              if (props.initFunction) {
                                props.initFunction(currentBlock.toAddress);
                              }
                              history.push(
                                `/address/${currentBlock.toAddress}`
                              );
                            }
                          }}
                        >
                          {currentBlock.toAddress}
                        </span>
                      </>
                    )}
                  </td>
                )}
              </OverlayTrigger>

              <td>
                {currentBlock.value} {constantObject.RewardUnits}
              </td>
              <td>{currentBlock.txnFee}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export default Transactions;
