import React, { useEffect, useState } from "react";
import NavBar from "../../components/Navbar";
import Footer from "../../components/Footer";
// import Select from "react-select";
import Bulb from "../../assets/images/Vector.png";
import { Link, useHistory, useParams } from "react-router-dom";
import { formatTimeStamp } from "../../common/Functions";
import { Tooltip } from "@mui/material";
import { FaRegClock } from "react-icons/fa";
import { Paper, Button } from "@material-ui/core";
import { IoHelpCircleOutline } from "react-icons/io5";
import axios from "axios";
import constantObject from "../../config";
import "./index.scss";
import "remixicon/fonts/remixicon.css";
import Loader from "../../components/Loader";

const TransactionDetail = () => {
  const [txnDetailData, setTxnDetailData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [showMore, setShowMore] = useState(false);
  // const [selectedOption, setSelectedOption] = useState(null);

  const history = useHistory();
  const { txnhash } = useParams();

  // const options = [
  //   { value: "option1", label: "Coins Game" },
  //   { value: "option2", label: "BC Game" },
  //   { value: "option3", label: "Nano Game" },
  // ];

  // const handleChange = (selected) => {
  //   setSelectedOption(selected);
  // };

  useEffect(() => {
    getTransactionsDetail();
  }, []);

  // get Transactions table data
  const getTransactionsDetail = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `${constantObject.BaseUrl}/api/transaction/${txnhash}`
      );

      const transactions_detail = response.data;

      setTxnDetailData(transactions_detail);
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <NavBar />

      {/* loader */}
      {isLoading && <Loader />}

      <div className="card-page-container">
        <header className="transactions-details-header">
          <div className="transactions-header-button">
            <h1 className="heading-blocks">Transaction Details</h1>
            {/* <Tooltip title="View previous transactions by sender" arrow>
              <Button
                variant="outlined"
                size="small"
                style={{
                  backgroundColor: "#e9ecef",
                  borderRadius: 6,
                  minWidth: "20px",
                  border: "1px solid #e9ecef",
                }}
              >
                <i
                  className="ri-arrow-left-s-line"
                  style={{ fontWeight: "600" }}
                ></i>
              </Button>
            </Tooltip>
            <Tooltip title="View next transactions by sender" arrow>
              <Button
                variant="outlined"
                size="small"
                style={{
                  backgroundColor: "#e9ecef",
                  borderRadius: 6,
                  minWidth: "20px",
                  border: "1px solid #e9ecef",
                }}
              >
                <i
                  className="ri-arrow-right-s-line"
                  style={{ fontWeight: "600" }}
                ></i>
              </Button>
            </Tooltip> */}
          </div>

          {/* <div className="transactions-header-button">
            <Select
              value={selectedOption}
              onChange={handleChange}
              options={options}
              placeholder="Play"
              styles={{
                control: (provided) => ({
                  ...provided,
                }),
              }}
            />
            <Select
              value={selectedOption}
              onChange={handleChange}
              options={options}
              placeholder="Gaming"
            />
          </div> */}
        </header>

        <div className="transactions-button-alignment">
          <div>
            <Button
              variant="outlined"
              size="small"
              style={{
                color: "#FFFFFF",
                backgroundColor: "#0784C3",
                textTransform: "capitalize",
                borderRadius: 8,
                marginRight: "10px",
              }}
            >
              Overview
            </Button>
            {/* <Button
              variant="outlined"
              size="small"
              style={{
                color: "#081D35",
                backgroundColor: "#e9ecef",
                textTransform: "capitalize",
                borderRadius: 8,
                border: "1px solid #e9ecef",
                marginRight: "10px",
              }}
            >
              Logs (2)
            </Button>
            <Button
              variant="outlined"
              size="small"
              style={{
                color: "#081D35",
                backgroundColor: "#e9ecef",
                textTransform: "capitalize",
                borderRadius: 8,
                border: "1px solid #e9ecef",
              }}
            >
              Comments
            </Button> */}
          </div>
          {/* <div>
            <Select
              value={selectedOption}
              onChange={handleChange}
              options={options}
              placeholder="More"
            />
          </div> */}
        </div>

        <Paper className="paper-style">
          <div className="block-container">
            <div className="row mb-3">
              <div className="col-md-4">
                <div className="block-style">
                  <IoHelpCircleOutline size={22} color="#6C757D" />
                  <span className="block-height-style">Transaction Hash:</span>
                </div>
              </div>
              <div className="col-md-8">
                <div className="block-style">
                  <span className="block-real-data">{txnDetailData.hash}</span>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-4">
                <div className="block-style">
                  <IoHelpCircleOutline size={22} color="#6C757D" />
                  <span className="block-height-style">Status:</span>
                </div>
              </div>
              <div className="col-md-8">
                <div className="block-style">
                  <Tooltip title="Status" arrow>
                    <Button
                      variant="outlined"
                      size="small"
                      className="ml-2"
                      style={{ color: "#00A186", textTransform: "capitalize" }}
                    >
                      {txnDetailData.status === 1 ? "Success" : "Pending"}
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-4">
                <div className="block-style">
                  <IoHelpCircleOutline size={22} color="#6C757D" />
                  <span className="block-height-style">Block:</span>
                </div>
              </div>
              <div className="col-md-8">
                <div className="block-style">
                  <div className="block-real-data">
                    <span
                      style={{ color: "#0784C3", cursor: "pointer" }}
                      onClick={() => {
                        history.push(
                          `/block_details/${txnDetailData.blockNumber}`
                        );
                      }}
                    >
                      {txnDetailData.blockNumber}
                    </span>
                    <Tooltip title="Number of block validated since" arrow>
                      <Button
                        variant="outlined"
                        size="small"
                        className="ml-2"
                        style={{ textTransform: "capitalize" }}
                      >
                        {txnDetailData.nonce} Block Confirmations
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-4">
                <div className="block-style">
                  <IoHelpCircleOutline size={22} color="#6C757D" />
                  <span className="block-height-style">Timestamp:</span>
                </div>
              </div>
              <div className="col-md-8">
                <div className="block-style">
                  <FaRegClock size={16} style={{ marginLeft: "6px" }} />
                  <span className="block-real-data">
                    {formatTimeStamp(txnDetailData.timestamp)}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="block-container">
            <div className="row mb-3">
              <div className="col-md-4">
                <div className="block-style">
                  <IoHelpCircleOutline size={22} color="#6C757D" />
                  <span className="block-height-style">
                    Transaction Action:
                  </span>
                </div>
              </div>
              <div className="col-md-8">
                <div className="block-style">
                  <div className="block-real-data" style={{ margin: "0px" }}>
                    <Tooltip title="Deposit" arrow>
                      <Button
                        variant="outlined"
                        size="small"
                        className="ml-2"
                        style={{ textTransform: "capitalize" }}
                      >
                        Deposit
                      </Button>
                    </Tooltip>
                    <span className="block-real-data">
                      Function by Validator: Ameritec
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="block-container">
            <div className="row mb-3">
              <div className="col-md-4">
                <div className="block-style">
                  <IoHelpCircleOutline size={22} color="#6C757D" />
                  <span className="block-height-style">From:</span>
                </div>
              </div>
              <div className="col-md-8">
                <div className="block-style">
                  <span className="block-real-data">
                    {txnDetailData.tokenData && txnDetailData.tokenData.from ? (
                      <Link
                        to={`/address/${txnDetailData.tokenData?.from}`}
                        style={{
                          color: "#0784C3",
                          textDecoration: "none",
                        }}
                      >
                        {txnDetailData.tokenData?.from}
                      </Link>
                    ) : (
                      <Link
                        to={`/address/${txnDetailData.from}`}
                        style={{
                          color: "#0784C3",
                          textDecoration: "none",
                        }}
                      >
                        {txnDetailData.from}
                      </Link>
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-4">
                <div className="block-style">
                  <IoHelpCircleOutline size={22} color="#6C757D" />
                  <span className="block-height-style">To:</span>
                </div>
              </div>
              <div className="col-md-8">
                <div className="block-style">
                  <span className="block-real-data">
                    {txnDetailData.tokenData && txnDetailData.tokenData.to ? (
                      <Link
                        to={`/address/${txnDetailData.tokenData?.to}`}
                        style={{
                          color: "#0784C3",
                          textDecoration: "none",
                        }}
                      >
                        {txnDetailData.tokenData?.to}
                      </Link>
                    ) : (
                      <Link
                        to={`/address/${txnDetailData.to}`}
                        style={{
                          color: "#0784C3",
                          textDecoration: "none",
                        }}
                      >
                        {txnDetailData.to}
                      </Link>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="block-container">
            <div className="row mb-3">
              <div className="col-md-4">
                <div className="block-style">
                  <IoHelpCircleOutline size={22} color="#6C757D" />
                  <span className="block-height-style">Value:</span>
                </div>
              </div>
              <div className="col-md-8">
                <div className="block-style">
                  <Tooltip
                    title="The amount of HEWE to be transfer to the recipient with the transactions"
                    arrow
                  >
                    <div className="gas-used-block">
                      {txnDetailData.tokenData &&
                      txnDetailData.tokenData.value ? (
                        <span
                          className="block-real-data"
                          style={{ marginLeft: "0px" }}
                        >
                          🔥
                          {txnDetailData.tokenData?.value}&nbsp;{txnDetailData.tokenData?.token}
                        </span>
                      ) : (
                        <span
                          className="block-real-data"
                          style={{ marginLeft: "0px" }}
                        >
                          🔥
                          {txnDetailData.value + " AMC"}
                        </span>
                      )}
                      {/* <span className="gas-percent-block">($22.079)</span> */}
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-4">
                <div className="block-style">
                  <IoHelpCircleOutline size={22} color="#6C757D" />
                  <span className="block-height-style">Transaction Fee:</span>
                </div>
              </div>
              <div className="col-md-8">
                <div className="block-style">
                  <Tooltip title="Gas Price * Gas used by Transaction" arrow>
                    <div className="gas-used-block">
                      <span className="block-real-data">
                        {txnDetailData.txnFee}
                      </span>
                      {/* <span className="gas-percent-block">($0.00)</span> */}
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-4">
                <div className="block-style">
                  <IoHelpCircleOutline size={22} color="#6C757D" />
                  <span className="block-height-style">Gas Price:</span>
                </div>
              </div>
              <div className="col-md-8">
                <div className="block-style">
                  <Tooltip
                    title="The price offer to the validator to purchase this amount of GAS (per gas)"
                    arrow
                  >
                    <div className="gas-used-block">
                      <span className="block-real-data">
                        {txnDetailData.gasPrice ? txnDetailData.gasPrice : "N/A"}
                      </span>
                      {/* <span className="gas-percent-block">(0 HEWE)</span> */}
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </Paper>

        {/* <Paper className="paper-style" style={{ marginTop: "1rem" }}>
          <div className="show-more-block-data row">
            <span className="more-details-text col-md-4">More Details:</span>
            <span
              className="show-more-details-data col-md-8"
              onClick={() => {
                setShowMore(!showMore);
              }}
            >
              {showMore ? "- Click to hide" : "+ Click to show more"}
            </span>
            {showMore && (
              <div>
                <p style={{ marginBottom: "0px", paddingLeft: "15px" }}>
                  Additional details...
                </p>
              </div>
            )}
          </div>
        </Paper> */}

        {/* <Paper className="paper-style" style={{ marginTop: "1rem" }}>
          <div className="show-more-block-data row">
            <div className="block-style col-md-4">
              <IoHelpCircleOutline size={22} color="#6C757D" />
              <span className="block-height-style">Private Note:</span>
            </div>
            <span className="transaction-login-link col-md-8">
              To access the <b>Private Note</b> feature, you must be{" "}
              <a href="#1" style={{ color: "#0784C3", textDecoration: "none" }}>
                Logged In
              </a>
            </span>
          </div>
        </Paper> */}

        <div className="bottom-content">
          <p className="last-block-content">
            <img src={Bulb} alt="logo" height={12.5} width={12.5} />
            Blocks are batches of transactions linked together via cryptographic
            hashes. Any tampering of a block invalidates subsequent blocks as
            their hashes would be changed.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default TransactionDetail;
