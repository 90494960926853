// const dev = {
//   BaseUrl: `https://explorer-api.dev.com`,
// };

// const local = {
//   BaseUrl: `http://localhost:3000`,
// };

// const prod = {
//   BaseUrl: `https://api.xyz.org`,
// };

// const config = {
//   ...(process.env.REACT_APP_STAGE === "prod"
//     ? prod
//     : process.env.REACT_APP_STAGE === "dev"
//     ? dev
//     : local),
// };

console.log("REACT_APP_STAGE:", process.env.REACT_APP_STAGE);

const dev = {
  BaseUrl: `https://api.amchain.net`,
};

const local = {
  BaseUrl: `http://localhost:3000`,
};

const prod = {
  BaseUrl: `https://api.amchain.net`,
};

const config = {
  ...(process.env.REACT_APP_STAGE === "prod"
    ? prod
    : process.env.REACT_APP_STAGE === "dev"
    ? dev
    : local),
};

export default config;
