import React from "react";
import { Container } from "react-bootstrap";
import Navbar from "../../components/Navbar";
import { getAddressTxns } from "../../common/Api";
import Footer from "../../components/Footer";

class TransactionsList extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {},
    };
  }

  componentDidMount() {
    this.init();
  }

  /**
   * Calls the  getAddress api and sets the result in state.
   */
  init = async () => {
    try {
      const txnDetail = await getAddressTxns(this.props.match.params.txid);
      //  txnDetail.timestamp = new Date(txnDetail.timestamp);
      this.setState({ data: txnDetail.data });
    } catch (e) {}
  };

  render() {
    return (
      <div>
        {" "}
        <Navbar></Navbar>

        <h1
          style={{
            paddingLeft: "20px",
            paddingTop: "20px",
            fontSize: "1.3125rem",
            tdor: "#4a4f55",
            fontWeight: 400,
            marginTop: "10px",
            marginBottom: "30px",
          }}
        >
          Transaction{" "}
        </h1>
        <h2>For {this.props.match.params.txid}</h2>
        <Container fluid className="container-width">
          {/* <Transactions txnsArr={this.state.data}>

           </Transactions> */}
        </Container>

        <Footer />
      </div>
    );
  }
}

export default TransactionsList;
