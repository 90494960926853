import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function OverlayQues(props) {
  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id="button-tooltip-2">{props.text}</Tooltip>}
    >
      {({ ref, ...triggerHandler }) => (
        <i
          ref={ref}
          {...triggerHandler}
          className="fa fa-question-circle"
          style={{
            fontColor: "#77838f",
            fontWeight: "normal",
            color: "grey",
          }}
        ></i>
      )}
    </OverlayTrigger>
  );
}

export default OverlayQues;
