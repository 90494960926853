import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "../../components/Navbar";
import Transactions from "../../components/Transactions";
import Pagination from "../../components/Pagination";
import { getBlockTxns } from "../../common/Api";

class TxnsForBlock extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
      per_page_records_txs: 25,
      total_pages_txs: 1,
      disable_first_txs: true,
      disable_last_txs: false,
      current_page_txs: 1,
    };
  }

  componentDidMount() {
    this.init(1);
  }

  /**
   * Calls the  transaction for a block api and sets the result in state.
   * @param {*} from : Page number to get the records from.
   */
  init = async (from) => {
    const txnsResult = await getBlockTxns(
      this.props.match.params.blockid,
      from,
      this.state.per_page_records_txs
    );
    // const txnsResult = await getTxns(from, this.state.per_page_records_txs);
    // const timeArray = [];
    let totalPages = 0;
    let disableLast = false;
    if (txnsResult.totalCount % this.state.per_page_records_txs === 0) {
      totalPages = txnsResult.totalCount / this.state.per_page_records_txs;
    } else {
      totalPages =
        parseInt(txnsResult.totalCount / this.state.per_page_records_txs) + 1;
    }

    if (totalPages === from) {
      disableLast = true;
    }

    this.setState({
      data: txnsResult.data,
      // txnsArr: txnsResult,
      total_records_txs: txnsResult.totalCount,
      total_pages_txs: totalPages,
      disable_last_txs: disableLast,
      current_page_txs: from,
    });
  };

  /**
   * Changes the drop down value for transactions and call the api to get new records
   * @param {*} selectedValue :Current selected value of dropdown.
   */
  handleSelect = (selectedValue) => {
    this.setState({ per_page_records_txs: selectedValue }, () => {
      this.init(1);
    });
  };

  /**
   *
   * Updates the current page in pagination(For transactions) buttons and disables and enables button according to currentpage.
   * @param {*} currentPage :Current updated page
   */
  updatePage = (currentPage) => {
    let first = true;
    let last = true;
    const { total_pages_tx: totalPage } = this.state;

    if (currentPage !== 1) {
      first = false;
    }

    if (currentPage !== totalPage) {
      last = false;
    }

    this.setState(
      {
        current_page_txs: currentPage,
        disable_first_txs: first,
        disable_last_txs: last,
      },
      () => this.init(currentPage)
    );
  };

  /**
   * Update current page value according to the type of button clicked.For transactions
   * @param {*} type :Type of button clicked (prev,next,first last )
   */
  performPagination = (type) => {
    const { total_pages_txs: totalPage } = this.state;
    let { current_page_txs: currentPage } = this.state;
    switch (type) {
      case "First":
        currentPage = 1;
        this.updatePage(currentPage);
        break;

      case "Prev":
        currentPage = currentPage - 1;
        this.updatePage(currentPage);
        break;
      case "Next":
        currentPage = currentPage + 1;
        this.updatePage(currentPage);
        break;
      case "Last":
        currentPage = totalPage;
        this.updatePage(currentPage);
        break;
      default:
        currentPage = 1;
        break;
    }
  };

  render() {
    return (
      <div>
        {" "}
        <Navbar></Navbar>
        <Container fluid className="container-width">
          <Row>
            <Col>
              {" "}
              <h2
                style={{
                  paddingTop: "20px",
                  fontSize: "2.3125rem",
                  color: "#77838f",
                  fontWeight: 400,
                  marginTop: "10px",
                  marginBottom: "30px",
                  marginLeft: "2px",
                }}
              >
                Transactions{" "}
              </h2>
              <h1
                style={{
                  paddingTop: "20px",
                  fontSize: "1.3125rem",
                  color: "#77838f",
                  fontWeight: 400,
                  marginTop: "10px",
                  marginBottom: "30px",
                  marginLeft: "2px",
                }}
              >
                For Block #{this.props.match.params.blockid}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Transactions txnsArr={this.state.data}></Transactions>
            </Col>
          </Row>
          <div className="paginationOuter">
            <Row>
              <Col>
                <Pagination
                  perPageRecords={this.state.per_page_records_txs}
                  disableFirst={this.state.disable_first_txs}
                  disableLast={this.state.disable_last_txs}
                  currentPage={this.state.current_page_txs}
                  totalPages={this.state.total_pages_txs}
                  onSelectDropdown={this.handleSelect}
                  onClickPageButton={this.performPagination}
                />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}

export default TxnsForBlock;
