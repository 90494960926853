import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import bulb from "../../assets/images/Vector.png";
import axios from "axios";
import constantObject from "../../config";
import RSearchBar from "material-ui-search-bar";
import LinearProgressBarWithLabel from "../../components/LinearProgressBar";
import { Tooltip } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { localTimeConvert, formatTimes } from "../../common/Functions";
import "./index.scss";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import Loader from "../../components/Loader";

const NewBlockPage = () => {
  const [cardValue, setCardValue] = useState({});
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [progress, setProgress] = useState(0);
  const [searchText, setSearchText] = useState("");

  const history = useHistory();

  const roundedNumber =
    cardValue && cardValue.blockReward24Hours
      ? cardValue.blockReward24Hours.toFixed(2)
      : 0;

  const cardData = [
    {
      title: "Network Utilization (24H)",
      value: `${cardValue.networkUtilization24Hours}%`,
    },
    {
      title: "BLOCK SIZE (24H)",
      value: `${cardValue.blockSize24Hours} Bytes`,
    },
    {
      title: "BLOCK REWARDS (24H)",
      value: `${roundedNumber} HEWE`,
    },
    { title: "Burnt Fees 🔥", value: "N/A" },
  ];

  // Function to handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //get card key
  const getBlockCardKey = async () => {
    try {
      const response = await axios.get(
        `${constantObject.BaseUrl}/api/stats/supported_queries`
      );

      getBlockCardValue(response.data);
    } catch (error) {
      console.log("Error fetching card key data:", error);
    }
  };

  //get card value
  const getBlockCardValue = async (val) => {
    try {
      const response = await axios.get(
        `${constantObject.BaseUrl}/api/stats/detail?query=${val[8]},${val[6]},${val[7]}`
      );

      setCardValue(response.data);
    } catch (error) {
      console.log("Error fetching card value data:", error);
    }
  };

  // Function to fetch updated block data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${constantObject.BaseUrl}/api/block?limit=${rowsPerPage}&page=${
            page + 1
          }`
        );

        const { data, totalCount } = response.data;

        setTableData(data);
        setTotalCount(totalCount);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    // Fetch data immediately on mount
    fetchData();

    // Set up interval for fetching updated data
    const intervalId = setInterval(fetchData, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [page, rowsPerPage]);

  useEffect(() => {
    getBlockCardKey();

    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleSearch = async (value) => {
    console.log("search", value);
    // try {
    //   const response = await axios.get(
    //     `${constantObject.BaseUrl}/api/search?id=${value}&limit=${rowsPerPage}&page=${
    //       page + 1
    //     }`
    //   );

    //   console.log(response)
    //   const { data } = response.data;

    //   setTableData(data);
    // } catch (error) {
    //   console.log(error);
    // }

    setSearchText(value);
    setPage(0);
  };

  const filterBlockTable = tableData.filter(
    (row) =>
      String(row.blockNumber)
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      String(row.hash).toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      <Navbar />

      {/* loader */}
      {isLoading && <Loader />}

      <div className="card-page-container">
        <header className="txn-block-search">
          <h1 className="heading-blocks heading-txn-block">Blocks</h1>
          
          <RSearchBar
            onChange={handleSearch}
            onCancelSearch={() => handleSearch("")}
            placeholder="Search by Txn Hash / Block"
            className="homepage-searchbar"
            style={{
              width: "50%",
              height: "38px",
            }}
          />
        </header>

        <div className="card-container">
          {cardData.map((card, index) => (
            <div className="top-card" key={index}>
              <div className="card-head">{card.title}</div>
              <div className="show-card-data">{card.value}</div>
            </div>
          ))}
        </div>

        <Paper className="paper-style">
          <div className="top-pagination">
            <div className="total-blocks-count">
              <span className="total-pending-txn">
                Total of {totalCount} blocks
              </span>
              <span className="block-record-show">
                {`Showing blocks between ${
                  page * rowsPerPage + 1
                } to ${Math.min((page + 1) * rowsPerPage, totalCount)}`}
              </span>
            </div>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className="block-pagination"
            />
          </div>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Block</TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    Age
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    Txn
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    Gas Used
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    Gas Limit
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    Reward
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    Burnt Fees (HEWE)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No data found
                    </TableCell>
                  </TableRow>
                ) : (
                  (filterBlockTable.length ? filterBlockTable : tableData).map(
                    (row) => (
                      <TableRow key={row._id} hover>
                        <TableCell
                          className="cell-style"
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            style={{ color: "#0784C3", cursor: "pointer" }}
                            onClick={() => {
                              history.push(`/block_details/${row.blockNumber}`);
                            }}
                          >
                            {row.blockNumber}
                          </span>
                        </TableCell>
                        <TableCell
                          className="cell-style"
                          style={{ textAlign: "center" }}
                        >
                          <Tooltip
                            title={localTimeConvert(row.timestamp)}
                            arrow
                          >
                            <span>{formatTimes(row.timestamp)}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          className="cell-style"
                          style={{ cursor: "pointer", textAlign: "center" }}
                        >
                          <Link
                            to="#"
                            style={{ color: "#0784C3", textDecoration: "none" }}
                          >
                            {row.transactionCount}
                          </Link>
                        </TableCell>
                        <TableCell
                          className="cell-style"
                          style={{ textAlign: "center" }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span>{row.gasUsed}</span>
                            <span className="progress-bar-percentage">
                              ({Math.round(progress)}%)
                            </span>
                          </div>
                          <LinearProgressBarWithLabel
                            value={progress}
                            width="50%"
                          />
                        </TableCell>
                        <TableCell
                          className="cell-style"
                          style={{ textAlign: "center" }}
                        >
                          {row.gasLimit}
                        </TableCell>
                        <TableCell
                          className="cell-style"
                          style={{ textAlign: "center" }}
                        >
                          {row.reward + " AMC"}
                        </TableCell>
                        <TableCell
                          className="cell-style"
                          style={{ textAlign: "center" }}
                        >
                          N/A
                        </TableCell>
                      </TableRow>
                    )
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        <div className="bottom-content">
          <p className="last-block-content">
            <img src={bulb} alt="logo" height={12.5} width={12.5} />
            Blocks are batches of transactions linked together via cryptographic
            hashes. Any tampering of a block invalidates subsequent blocks as
            their hashes would be changed.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default NewBlockPage;
