import React, { useState, useEffect } from "react";
// import { FaEye } from "react-icons/fa";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import RSearchBar from "material-ui-search-bar";
import bulb from "../../assets/images/Vector.png";
import axios from "axios";
import constantObject from "../../config";
import { Tooltip } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { IoHelpCircleOutline } from "react-icons/io5";
import { localTimeConvert, formatTimes } from "../../common/Functions";
import "./index.scss";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import Loader from "../../components/Loader";

const NewTransactions = () => {
  const [cardValue, setCardValue] = useState({});
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchText, setSearchText] = useState("");

  const history = useHistory();

  const cardData = [
    {
      title: "Transactions (24H)",
      // value: `${cardValue.txnCount24Hours} <span class='card-substyle'>(5.02%)</span>`,
      value: `${cardValue.txnCount24Hours}`,
    },
    {
      title: "Pending Transactions (Last 1H)",
      value: `N/A`,
    },
    {
      title: "Network Transactions Fee (24H)",
      value: `${cardValue.networkUtilization24Hours}`,
    },
    {
      title: "Avg. Transaction Fee (24h)",
      value: `${cardValue.avgTxnFee24Hours}`,
    },
  ];

  // Function to handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //get card key
  const getTransactionCardKey = async () => {
    try {
      const response = await axios.get(
        `${constantObject.BaseUrl}/api/stats/supported_queries`
      );

      getTransactionCardValue(response.data);
    } catch (error) {
      console.log("Error fetching card key data:", error);
    }
  };

  //get card value
  const getTransactionCardValue = async (val) => {
    try {
      const response = await axios.get(
        `${constantObject.BaseUrl}/api/stats/detail?query=${val[9]},${val[8]},${val[11]}`
      );

      setCardValue(response.data);
    } catch (error) {
      console.log("Error fetching card value data:", error);
    }
  };

  // Function to fetch updated transaction data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${
            constantObject.BaseUrl
          }/api/transaction?limit=${rowsPerPage}&page=${page + 1}`
        );

        const { data, totalCount } = response.data;

        setTableData(data);
        setTotalCount(totalCount);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    // Fetch data immediately on mount
    fetchData();

    // Set up interval for fetching updated data
    const intervalId = setInterval(fetchData, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [page, rowsPerPage]);

  useEffect(() => {
    getTransactionCardKey();
  }, []);

  const handleSearch = async (value) => {
    // try {
    //   const response = await axios.get(
    //     `${constantObject.BaseUrl}/api/search?id=${value}&limit=${rowsPerPage}&page=${
    //       page + 1
    //     }`
    //   );

    //   console.log(response)
    //   const { data } = response.data;

    //   setTableData(data);
    // } catch (error) {
    //   console.log(error);
    // }

    setSearchText(value);
    setPage(0);
  };

  const filterBlockTable = tableData.filter(
    (row) =>
      String(row.blockNumber)
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      String(row.hash).toLowerCase().includes(searchText.toLowerCase()) ||
      String(row.from).toLowerCase().includes(searchText.toLowerCase()) ||
      String(row.to).toLowerCase().includes(searchText.toLowerCase()) ||
      String(row.tokenData?.from).toLowerCase().includes(searchText.toLowerCase()) ||
      String(row.tokenData?.to).toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      <Navbar />

      {/* loader */}
      {isLoading && <Loader />}

      <div className="card-page-container">
        <header className="txn-block-search">
          <h1 className="heading-pending-txn heading-txn-block">
            Transactions
          </h1>

          <RSearchBar
            onChange={handleSearch}
            onCancelSearch={() => handleSearch("")}
            placeholder="Search by Txn Hash / Block / Address"
            className="homepage-searchbar"
            style={{
              width: "50%",
              height: "38px",
            }}
          />
        </header>

        <div className="card-container">
          {cardData.map((card, index) => (
            <div className="top-card" key={index}>
              <div className="card-head">{card.title}</div>
              <div
                className="show-card-data"
                dangerouslySetInnerHTML={{ __html: card.value }}
              />
            </div>
          ))}
        </div>

        <Paper className="paper-style">
          <div className="top-pagination">
            <div className="total-blocks-count">
              <span className="total-pending-txn">
                A total of {totalCount} transactions found
              </span>
              <span className="block-record-show">
                {`Showing transactions between ${
                  page * rowsPerPage + 1
                } to ${Math.min((page + 1) * rowsPerPage, totalCount)}`}
              </span>
            </div>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableCell style={{ fontWeight: "bold" }}>
                    <Tooltip
                      title="See previous of the transactions details"
                      arrow
                    >
                      <span>
                        <IoHelpCircleOutline size={22} color="#6C757D" />
                      </span>
                    </Tooltip>
                  </TableCell> */}
                  <TableCell style={{ fontWeight: "bold" }}>Txn Hash</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Method <IoHelpCircleOutline size={22} color="#6C757D" />
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Block</TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#0784C3" }}>
                    Age
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>From</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>To</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Value</TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#0784C3" }}>
                    Txn Fee
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      No data found
                    </TableCell>
                  </TableRow>
                ) : (
                  (filterBlockTable.length ? filterBlockTable : tableData).map(
                    (row) => (
                      <TableRow key={row._id} hover>
                        {/* <TableCell className="cell-style">
                          <Button
                            variant="outlined"
                            size="small"
                            style={{
                              backgroundColor: "#F8F9FA",
                              borderRadius: 8,
                              minWidth: "28px",
                            }}
                          >
                            <FaEye />
                          </Button>
                        </TableCell> */}
                        <TableCell
                          className="cell-style"
                          style={{ cursor: "pointer" }}
                        >
                          <Tooltip title={row.hash} arrow>
                            <span
                              style={{ color: "#0784C3" }}
                              onClick={() => {
                                history.push(
                                  `/transactions_detail/${row.hash}`
                                );
                              }}
                            >
                              {row.hash.length > 26
                                ? `${row.hash.substring(0, 26)}...`
                                : row.hash}
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell className="cell-style">
                          <Tooltip
                            title={
                              row.tokenData && row.tokenData.method
                                ? row.tokenData.method
                                : "Transfer"
                            }
                            arrow
                          >
                            <Button
                              variant="outlined"
                              color="default"
                              size="small"
                              style={{
                                fontSize: "11px",
                                fontWeight: "400",
                                backgroundColor: "#F8F9FA",
                                borderRadius: 8,
                                textTransform: "capitalize",
                                minWidth: "90px",
                              }}
                            >
                              {row.tokenData && row.tokenData.method
                                ? row.tokenData.method
                                : "Transfer"}
                            </Button>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          className="cell-style"
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            style={{ color: "#0784C3", cursor: "pointer" }}
                            onClick={() => {
                              history.push(`/block_details/${row.blockNumber}`);
                            }}
                          >
                            {row.blockNumber}
                          </span>
                        </TableCell>
                        <TableCell className="cell-style">
                          <Tooltip
                            title={localTimeConvert(row.blockTimestamp)}
                            arrow
                          >
                            <span>{formatTimes(row.blockTimestamp)}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          className="cell-style"
                          style={{ color: "#0784C3" }}
                        >
                          {row.tokenData && row.tokenData.from ? (
                            <Tooltip title={row.tokenData?.from} arrow>
                              <Link
                                to={`/address/${row.tokenData?.from}`}
                                style={{
                                  color: "#0784C3",
                                  textDecoration: "none",
                                }}
                              >
                                {row.tokenData?.from.length > 26
                                  ? `${row.tokenData?.from.substring(0, 26)}...`
                                  : row.tokenData?.from}
                              </Link>
                            </Tooltip>
                          ) : (
                            <Tooltip title={row.from} arrow>
                              <Link
                                to={`/address/${row.from}`}
                                style={{
                                  color: "#0784C3",
                                  textDecoration: "none",
                                }}
                              >
                                {row.from.length > 26
                                  ? `${row.from.substring(0, 26)}...`
                                  : row.from}
                              </Link>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell
                          className="cell-style"
                          style={{ color: "#0784C3" }}
                        >
                          {row.tokenData && row.tokenData.to ? (
                            <Tooltip title={row.tokenData?.to} arrow>
                              <Link
                                to={`/address/${row.tokenData?.to}`}
                                style={{
                                  color: "#0784C3",
                                  textDecoration: "none",
                                }}
                              >
                                {row.tokenData?.to.length > 26
                                  ? `${row.tokenData?.to.substring(0, 26)}...`
                                  : row.tokenData?.to}
                              </Link>
                            </Tooltip>
                          ) : (
                            <Tooltip title={row.to} arrow>
                              <Link
                                to={`/address/${row.to}`}
                                style={{
                                  color: "#0784C3",
                                  textDecoration: "none",
                                }}
                              >
                                {row.to.length > 26
                                  ? `${row.to.substring(0, 26)}...`
                                  : row.to}
                              </Link>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell className="cell-style">
                          {row.tokenData && row.tokenData.value ? (
                            <Tooltip
                              title={`${row.tokenData?.value} ${row.tokenData?.token}`}
                              arrow
                            >
                              <span>
                              {row.tokenData?.value}&nbsp;{row.tokenData?.token}
                              </span>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={row.value + " AMC"}
                              arrow
                            >
                              <span>
                                {row.value + " AMC"}
                              </span>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell className="cell-style">
                          {row.txnFee}
                        </TableCell>
                      </TableRow>
                    )
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        <div className="bottom-content">
          <p className="last-block-content">
            <img src={bulb} alt="logo" height={12.5} width={12.5} />
            Blocks are batches of transactions linked together via cryptographic
            hashes. Any tampering of a block invalidates subsequent blocks as
            their hashes would be changed.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NewTransactions;
