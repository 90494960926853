import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
// import Typography from '@mui/material/Typography';

const LinearProgressBarWithLabel = ({ value, label, width }) => {
  return (
    <div style={{ width: width || '100%' }}>
      {/* <Typography variant="body2" gutterBottom>
        {label} - {Math.round(value)}%
      </Typography> */}
      <LinearProgress variant="determinate" value={value} />
    </div>
  );
};

export default LinearProgressBarWithLabel;
