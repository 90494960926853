import React from "react";
import Homepage from "./container/Homepage/HomePage";
import Transactions from "./container/Transactions";
import TransactionsList from "./container/TransactionsList";
import TxnsForBlock from "./container/TxnsForBlock";
// import Address from "./container/Address";
import About from "./components/About";
import { Switch, Route } from "react-router-dom";
import BlockDetailPage from "./container/BlockDetailPage";
import PendingTransaction from "./container/PendingTransactions";
import NewBlockPage from "./container/BlockDetailPage/NewBlockPage";
import NewTransactions from "./container/Transactions/NewTransactions";
import BlockDetail from "./container/BlockDetailPage/BlockDetail";
import TransactionDetail from "./container/Transactions/TransactionaDetail";
import TransactionForBlock from "./container/TxnsForBlock/TransactionFoeBlock";
import AddressForTxn from "./container/Address/Address";

function App() {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={Homepage}></Route>
        <Route path="/txs/:txid" component={Transactions}></Route>
        <Route path="/about" component={About}></Route>
        <Route path="/block/:blockid" component={BlockDetailPage}></Route>
        {/* <Route path="/address/:hash" component={Address}></Route> */}
        <Route path="/transaction/:txid" component={TransactionsList}></Route>
        <Route path="/txns/:blockid" component={TxnsForBlock}></Route>
        <Route path="/new_transactions" component={NewTransactions}></Route> 
        <Route path="/transactions_detail/:txnhash" component={TransactionDetail}></Route> 
        <Route path="/pending_transactions" component={PendingTransaction}></Route> 
        <Route path="/new_block_page" component={NewBlockPage}></Route>
        <Route path="/block_details/:blocknumber" component={BlockDetail}></Route>
        <Route path="/txn_for_block/:blocknumber" component={TransactionForBlock}></Route>
        <Route path="/address/:ads" component={AddressForTxn}></Route>
      </Switch>
    </div>
  );
}

export default App;
