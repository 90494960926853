import React from "react";
import { FaTwitter } from "react-icons/fa";
import { TiArrowUp } from "react-icons/ti";
import { Link } from "react-router-dom";
import footerlogo from "../assets/images/footer-logo.png";
import clicktop from "../assets/images/icon-to-top.png";

function Footer() {
  const linkStyle = {
    textDecoration: "none",
  };

  return (
    <>
      <div className="footer-container">
        <div className="top-footer-section">
          <div className="top-footer-section-content">
            {/* <a
              href="#1"
              target="_blank"
              rel="noopener noreferrer"
              style={linkStyle}
            >
              <FaTwitter
                size={15}
                color="#081D35"
                style={{ marginRight: "6px" }}
              />
              <span className="social-name">Twitter</span>
            </a> */}
          </div>
          <div className="top-footer-section-content">
            <Link
              to="#"
              style={linkStyle}
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              <TiArrowUp
                size={20}
                color="#081D35"
                style={{ marginRight: "6px" }}
              />
              {/* <img
                src={clicktop}
                alt="Top icon"
                style={{ height: "12px", width: "12px", marginRight: "6px" }}
              /> */}
              <span className="social-name">Back to Top</span>
            </Link>
          </div>
        </div>

        <div className="second-footer-section">
          <div className="left-footer-section">
            <div className="top-footer-section-content">
              <img src={footerlogo} alt="Logo" className="footer-logo-style" />
              <span className="powered-brand-name">Powered by AmChain</span>
            </div>
            <div style={{ padding: "6px 0px", width: "307px" }}>
              <p className="brand-text">
                AMC Smart Chain Explorer and analytics platform for AmChain.
              </p>
            </div>
          </div>
          <div className="right-footer-section">
            <div className="right-footer-content">
              <div className="company">
                <span className="about-company">Company</span>
              </div>
              <div className="footer-link">
                <div className="about-us">
                  <Link to="" style={linkStyle}>
                    <span className="comon-right-footer">About Us</span>
                  </Link>
                </div>
                <div className="contact-us">
                  <Link to="" style={linkStyle}>
                    <span className="comon-right-footer">Contact Us</span>
                  </Link>
                </div>
                <div className="privacy-policy">
                  <Link to="" style={linkStyle}>
                    <span className="comon-right-footer">Privacy Policy</span>
                  </Link>
                </div>
                <div className="term-privacy">
                  <Link to="" style={linkStyle}>
                    <span className="comon-right-footer">Terms & Privacy</span>
                  </Link>
                </div>
                <div className="bug-bounty">
                  <Link to="" style={linkStyle}>
                    <span className="comon-right-footer">Bug Bounty</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="last-footer-section">
          <span className="footer-copyright">AmChain © 2024 &nbsp;|</span>
          <span className="built-by-team">⛏ Built by Team</span>
          <a
            href="https://ameritecps.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={linkStyle}
            className="footer-ameritec"
          >
            Ameritec
          </a>
        </div>
      </div>
    </>
  );
}

export default Footer;
