export const constantObject = {
  BaseUrl: `http://localhost:3000`,
  RewardUnits: "Ether",
  GasPriceUnits: "Gwei",
  BlockNumberDescription:
    "The number of the block in which the transaction was recorded.",
  TimeStampDescription: "The date and time at which a block is mined.",
  TransactionNumber: "The number of transactions in the block.",
  Miner: "Miner who successfully include the block onto the blockchain.",
  Reward:
    "For each block, the miner is rewarded with a finite amount of Ether on top of the fees paid for all transactions in the block.",
  Uncles:
    "An uncle block has a significantly lower reward than a normal block. Uncles reward is valid but rejected as it is not on the longest chain which is the working mechanism of the blockchain. Uncle block is important in Ethereum as it secures the blockchain.",
  Difficulty:
    "The amount of effort required to mine a new block. The difficulty algorithm may adjust according to time.",
  TotalDifficulty: "Total difficulty of the chain until this block.",
  BlockSize: "The block size is actually determined by the block's gas limit.",
  GasUsed:
    "The total gas used in the block and its percentage of gas filled in the block.",
  GasLimit: "Total gas limit provided by all transactions in the block.",
  ExtraData: "Any data that can be included by the miner in the block.",
  Hash: "The hash of the block header of the current block.",
  ParentHash:
    "The hash of the block from which this block was generated, also known as its parent block.",
  StateRoot: "The root of the state trie",
  Nonce:
    "Block nonce is a value used during mining to demonstrate proof of work for a block.",
  TransactionHash:
    "A TxHash or transaction hash is a unique 66 characters identifier that is generated whenever a transaction is executed.",
  Status: "The status of the transaction.",
  FromAddress:
    "The sending party of the transaction (could be from a contract address).",
  ToAddress:
    "The receiving party of the transaction (could be a contract address).",
  Value: "The value being transacted in Ether. ",
  Fee: "Amount paid to the miner for processing the transaction.",
  GasPrice:
    "Cost per unit of gas specified for the transaction, in Gwei. The higher the gas price the higher chance of getting included in a block.",
  InputData: "Additional information that is required for the transaction.",
  Age: "Time when transaction occured",
};

export const addressType = {
  ADDRESS: "ADDRESS",
  CONTRACT: "CONTRACT",
};

export const txnType = {
  CONTRACT_CREATION: "CONTRACT_CREATION",
  CONTRACT_CALL: "CONTRACT_CALL",
  TRANSFER: "TRANSFER",
};
