import React from "react";
import { Button, Dropdown, InputGroup, DropdownButton } from "react-bootstrap";

function Pagination(props) {
  return (
    <div className="d-md-flex justify-content-between my-3 w-100">
      <div className="d-flex align-items-center text-secondary mb-2 mb-md-0 shownumbers">
        <span className="show">Show</span>
        <DropdownButton
          as={InputGroup.Prepend}
          size="sm"
          variant="outline-secondary"
          title={props.perPageRecords}
          id="input-group-dropdown-1"
          onSelect={(e) => props.onSelectDropdown(e)}
          className="custom-select-xs drop-down"
        >
          <Dropdown.Item eventKey="10">10</Dropdown.Item>
          <Dropdown.Item eventKey="25">25</Dropdown.Item>
          <Dropdown.Item eventKey="50">50</Dropdown.Item>
          <Dropdown.Item eventKey="100">100</Dropdown.Item>
        </DropdownButton>

        <span className="records">Records</span>
      </div>
      <div className="mb-4 mb-md-0">
        <Button
          className="custom-button-xs page-btn"
          size="sm"
          onClick={() => props.onClickPageButton("First")}
          disabled={props.disableFirst}
        >
          First
        </Button>
        {"  "}
        <Button
          className="custom-button-xs"
          size="sm"
          onClick={() => props.onClickPageButton("Prev")}
          disabled={props.disableFirst}
        >
          {"<"}
        </Button>
        <Button disabled={true} className="custom-button-xs">
          Page {props.currentPage} of {props.totalPages}
        </Button>
        <Button
          className="custom-button-xs"
          size="sm"
          onClick={() => props.onClickPageButton("Next")}
          disabled={props.disableLast}
        >
          {">"}
        </Button>
        {"  "}
        <Button
          className="custom-button-xs"
          size="sm"
          onClick={() => props.onClickPageButton("Last")}
          disabled={props.disableLast}
        >
          Last
        </Button>
      </div>
    </div>
  );
}

export default Pagination;
