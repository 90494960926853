import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import RSearchBar from "material-ui-search-bar";
import { Tooltip } from "@mui/material";
import "./index.scss";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Link,
} from "@material-ui/core";

const PendingTransaction = () => {
  // State for handling pagination and search
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");

  // Dummy data for the table
  const rows = [
    {
      id: 1,
      Txn: "0xcfc3e7c925f32c4459d...",
      method: "Sending",
      nonce: 1201,
      lastseen: "1 sec ago",
      gaslimit: 123456,
      gasprice: "4 Gwei",
      from: "0xf915Ed...5a1a94Be",
      to: "0xf915Ed...5a1a94Be",
      amount: "03 HEWE",
    },
    {
      id: 2,
      Txn: "0xcfc3e7c925f32c8578d...",
      method: "Transfer",
      nonce: 1202,
      lastseen: "2 sec ago",
      gaslimit: 136457,
      gasprice: "2 Gwei",
      from: "0xf915Ed...5a1a94Be",
      to: "0xf915Ed...5a1a94Be",
      amount: "03 HEWE",
    },
    {
      id: 3,
      Txn: "0xcfc3e7c925f32c859d...",
      method: "Withdraw",
      nonce: 1203,
      lastseen: "3 sec ago",
      gaslimit: 136456,
      gasprice: "3 Gwei",
      from: "0xf915Ed...5a1a94Be",
      to: "0xf915Ed...5a1a94Be",
      amount: "03 HEWE",
    },
    {
      id: 4,
      Txn: "0xcfc3e7c943f32c859d...",
      method: "Pending",
      nonce: 1204,
      lastseen: "1 sec ago",
      gaslimit: 136456,
      gasprice: "4 Gwei",
      from: "0xf915Ed...5a1a94Be",
      to: "0xf915Ed...5a1a94Be",
      amount: "03 HEWE",
    },
    {
      id: 5,
      Txn: "0xcfc3e7c925f78c859d...",
      method: "Aprove",
      nonce: 1205,
      lastseen: "1 sec ago",
      gaslimit: 133356,
      gasprice: "4 Gwei",
      from: "0xf915Ed...5a1a94Be",
      to: "0xf915Ed...5a1a94Be",
      amount: "03 HEWE",
    },
    {
      id: 6,
      Txn: "0xcfc3e7c925gg2c859d...",
      method: "Transfer",
      nonce: 1206,
      lastseen: "1 sec ago",
      gaslimit: 156456,
      gasprice: "4 Gwei",
      from: "0xf915Ed...5a1a94Be",
      to: "0xf915Ed...5a1a94Be",
      amount: "03 HEWE",
    },
    {
      id: 7,
      Txn: "0xcfc3e7c925f32c859d...",
      method: "Transfer",
      nonce: 1207,
      lastseen: "1 sec ago",
      gaslimit: 136456,
      gasprice: "4 Gwei",
      from: "0xf915Ed...5a1a94Be",
      to: "0xf915Ed...5a1a94Be",
      amount: "03 HEWE",
    },
    {
      id: 8,
      Txn: "0xcfc3e7c925f32c859d...",
      method: "Transfer",
      nonce: 1208,
      lastseen: "1 sec ago",
      gaslimit: 136456,
      gasprice: "4 Gwei",
      from: "0xf915Ed...5a1a94Be",
      to: "0xf915Ed...5a1a94Be",
      amount: "03 HEWE",
    },
    {
      id: 9,
      Txn: "0xcfc3e7c925f32c859d...",
      method: "Transfer",
      nonce: 1209,
      lastseen: "1 sec ago",
      gaslimit: 136456,
      gasprice: "4 Gwei",
      from: "0xf915Ed...5a1a94Be",
      to: "0xf915Ed...5a1a94Be",
      amount: "03 HEWE",
    },
    {
      id: 10,
      Txn: "0xcfc3e7c925f32c859d...",
      method: "Transfer",
      nonce: 1210,
      lastseen: "1 sec ago",
      gaslimit: 136456,
      gasprice: "4 Gwei",
      from: "0xf915Ed...5a1a94Be",
      to: "0xf915Ed...5a1a94Be",
      amount: "03 HEWE",
    },
    {
      id: 11,
      Txn: "0xcfc3e7c925f32c859d...",
      method: "Transfer",
      nonce: 1211,
      lastseen: "1 sec ago",
      gaslimit: 136456,
      gasprice: "4 Gwei",
      from: "0xf915Ed...5a1a94Be",
      to: "0xf915Ed...5a1a94Be",
      amount: "03 HEWE",
    },
    {
      id: 12,
      Txn: "0xcfc3e7c925f32c859d...",
      method: "Transfer",
      nonce: 1212,
      lastseen: "1 sec ago",
      gaslimit: 136456,
      gasprice: "4 Gwei",
      from: "0xf915Ed...5a1a94Be",
      to: "0xf915Ed...5a1a94Be",
      amount: "03 HEWE",
    },
    {
      id: 13,
      Txn: "0xcfc3e7c925f32c859d...",
      method: "Transfer",
      nonce: 1213,
      lastseen: "1 sec ago",
      gaslimit: 136456,
      gasprice: "4 Gwei",
      from: "0xf915Ed...5a1a94Be",
      to: "0xf915Ed...5a1a94Be",
      amount: "03 HEWE",
    },
    {
      id: 14,
      Txn: "0xcfc3e7c925f32c859d...",
      method: "Transfer",
      nonce: 1214,
      lastseen: "1 sec ago",
      gaslimit: 136456,
      gasprice: "4 Gwei",
      from: "0xf915Ed...5a1a94Be",
      to: "0xf915Ed...5a1a94Be",
      amount: "03 HEWE",
    },
    {
      id: 15,
      Txn: "0xcfc3e7c925f32c859d...",
      method: "Transfer",
      nonce: 1215,
      lastseen: "1 sec ago",
      gaslimit: 136456,
      gasprice: "4 Gwei",
      from: "0xf915Ed...5a1a94Be",
      to: "0xf915Ed...5a1a94Be",
      amount: "03 HEWE",
    },
  ];

  // Function to handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = (value) => {
    setSearchText(value);
    setPage(0);
  };

  const filteredRows = rows.filter(
    (row) =>
      String(row.Txn).toLowerCase().includes(searchText.toLowerCase()) ||
      String(row.lastseen).toLowerCase().includes(searchText.toLowerCase()) ||
      String(row.gasprice).toLowerCase().includes(searchText.toLowerCase()) ||
      String(row.nonce).toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      <Navbar />

      <div className="card-page-container">
        <header
          style={{ borderBottom: "2px solid #E9ECEF", paddingBottom: "1.5rem" }}
        >
          <h1 className="heading-pending-txn">Pending Transactions</h1>
        </header>

        <Paper className="paper-style" style={{ marginTop: "1.5rem" }}>
          <TableContainer>
            <div className="pending-searchbar">
              <Button
                variant="outlined"
                color="default"
                size="small"
                style={{
                  backgroundColor: "#F8F9FA",
                  borderRadius: 8,
                  textTransform: "capitalize",
                }}
              >
                <Tooltip title="Click to see Pending Transactions Pool" arrow>
                  <span>Pending Transaction Pool</span>
                </Tooltip>
              </Button>

              <RSearchBar
                onChange={handleSearch}
                placeholder="Filter by Address"
                style={{
                  width: "50%",
                  height: "38px",
                  border: "none",
                  outline: "none",
                }}
              />
            </div>

            <div className="top-pagination">
              <div>
                <span className="total-pending-txn">
                  A total of {filteredRows.length || rows.length} pending txns
                  found static
                </span>
              </div>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={filteredRows.length || rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Txn Hash</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Method</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Nonce</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Last Seen
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Gas Limit
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Gas Price
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>From</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>To</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(filteredRows.length || rows.length) === 0 ? (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No data found
                    </TableCell>
                  </TableRow>
                ) : (
                  (filteredRows.length ? filteredRows : rows)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow key={row.id}>
                        <TableCell
                          className="cell-style"
                          style={{ cursor: "pointer" }}
                        >
                          <Link
                            to="#"
                            style={{ color: "#0784C3", textDecoration: "none" }}
                          >
                            {row.Txn}
                          </Link>
                        </TableCell>
                        <TableCell className="cell-style">
                          <Button
                            variant="outlined"
                            color="default"
                            size="small"
                            style={{
                              backgroundColor: "#F8F9FA",
                              borderRadius: 8,
                              textTransform: "capitalize",
                              minWidth: "90px",
                            }}
                          >
                            <Tooltip title={row.method} arrow>
                              <span>{row.method}</span>
                            </Tooltip>
                          </Button>
                        </TableCell>
                        <TableCell className="cell-style">
                          {row.nonce}
                        </TableCell>
                        <TableCell className="cell-style">
                          <Tooltip title={row.lastseen} arrow>
                            <span>{row.lastseen}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell className="cell-style">
                          {row.gaslimit}
                        </TableCell>
                        <TableCell className="cell-style">
                          <Tooltip title={row.gasprice} arrow>
                            <span>{row.gasprice}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          className="cell-style"
                          style={{ color: "#0784C3" }}
                        >
                          <Tooltip title={row.from} arrow>
                            <span>{row.from}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          className="cell-style"
                          style={{ color: "#0784C3" }}
                        >
                          <Tooltip title={row.to} arrow>
                            <span> {row.to}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell className="cell-style">
                          {row.amount}
                        </TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={filteredRows.length || rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      <Footer />
    </>
  );
};

export default PendingTransaction;
