import React, { useState, useEffect } from "react";
import { FaEye } from "react-icons/fa";
import { IoHelpCircleOutline } from "react-icons/io5";
import { useHistory, useParams, Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader";
import NavBar from "../../components/Navbar";
import bulb from "../../assets/images/Vector.png";
import axios from "axios";
import constantObject from "../../config";
import { localTimeConvert, formatTimes } from "../../common/Functions";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";

const TransactionFoeBlock = () => {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const history = useHistory();
  const { blocknumber } = useParams();

  // Function to handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getTransactionsForBlock();
  }, [page, rowsPerPage]);

  // get Transactions table data
  const getTransactionsForBlock = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `${
          constantObject.BaseUrl
        }/api/block/${blocknumber}/txns?limit=${rowsPerPage}&page=${page + 1}`
      );

      const transactions_data = response.data.data;
      const totalCount = response.data.totalCount;

      setTableData([...transactions_data]);
      setTotalCount(totalCount);
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <NavBar />

      {/* loader */}
      {isLoading && <Loader />}

      <div className="card-page-container">
        <header className="txn-for-block">
          <h1 className="heading-pending-txn">Transactions</h1>
          <span style={{marginLeft: "6px"}}>
            For Block{" "}
            <Link
              to={`/block_details/${blocknumber}`}
              className="txn-block-redirect"
            >
              {blocknumber}
            </Link>
          </span>
        </header>

        <Paper className="paper-style" style={{ marginTop: "1.5rem" }}>
          <div className="top-pagination">
            <div className="total-blocks-count">
              <span className="total-pending-txn">
                A total of {totalCount} transactions found
              </span>
              <span className="block-record-show">
                {`Showing transactions for block between ${
                  page * rowsPerPage + 1
                } to ${Math.min((page + 1) * rowsPerPage, totalCount)}`}
              </span>
            </div>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableCell style={{ fontWeight: "bold" }}>
                    <Tooltip
                      title="See previous of the transactions details"
                      arrow
                    >
                      <span>
                        <IoHelpCircleOutline size={22} color="#6C757D" />
                      </span>
                    </Tooltip>
                  </TableCell> */}
                  <TableCell style={{ fontWeight: "bold" }}>Txn Hash</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Method <IoHelpCircleOutline size={22} color="#6C757D" />
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Block</TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#0784C3" }}>
                    Age
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>From</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>To</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Value</TableCell>
                  <TableCell style={{ fontWeight: "bold", color: "#0784C3" }}>
                    Txn Fee
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      No data found
                    </TableCell>
                  </TableRow>
                ) : (
                  tableData.map((row) => (
                    <TableRow key={row._id} hover>
                      {/* <TableCell className="cell-style">
                          <Button
                            variant="outlined"
                            size="small"
                            style={{
                              backgroundColor: "#F8F9FA",
                              borderRadius: 8,
                              minWidth: "28px",
                            }}
                          >
                            <FaEye />
                          </Button>
                        </TableCell> */}
                      <TableCell
                        className="cell-style"
                        style={{ cursor: "pointer" }}
                      >
                        <Tooltip title={row.hash} arrow>
                          <span
                            style={{ color: "#0784C3" }}
                            onClick={() => {
                              history.push(`/transactions_detail/${row.hash}`);
                            }}
                          >
                            {row.hash.length > 26
                              ? `${row.hash.substring(0, 26)}...`
                              : row.hash}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell className="cell-style">
                        <Tooltip
                          title={row.status === 1 ? "Withdraw" : "Transfer"}
                          arrow
                        >
                          <Button
                            variant="outlined"
                            color="default"
                            size="small"
                            style={{
                              fontSize: "11px",
                              fontWeight: "400",
                              backgroundColor: "#F8F9FA",
                              borderRadius: 8,
                              textTransform: "capitalize",
                              minWidth: "90px",
                            }}
                          >
                            {row.status === 1 ? "Withdraw" : "Transfer"}
                          </Button>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className="cell-style"
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          style={{ color: "#0784C3", cursor: "pointer" }}
                          onClick={() => {
                            history.push(`/block_details/${row.blockNumber}`);
                          }}
                        >
                          {row.blockNumber}
                        </span>
                      </TableCell>
                      <TableCell className="cell-style">
                        <Tooltip
                          title={localTimeConvert(row.blockTimestamp)}
                          arrow
                        >
                          <span>{formatTimes(row.blockTimestamp)}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className="cell-style"
                        style={{ color: "#0784C3" }}
                      >
                        <Tooltip title={row.from} arrow>
                          <Link
                            to={`/address/${row.to}`}
                            style={{
                              color: "#0784C3",
                              textDecoration: "none",
                            }}
                          >
                            {row.from.length > 26
                              ? `${row.from.substring(0, 26)}...`
                              : row.from}
                          </Link>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className="cell-style"
                        style={{ color: "#0784C3" }}
                      >
                        <Tooltip title={row.to} arrow>
                          <Link
                            to={`/address/${row.to}`}
                            style={{
                              color: "#0784C3",
                              textDecoration: "none",
                            }}
                          >
                            {row.to.length > 26
                              ? `${row.to.substring(0, 26)}...`
                              : row.to}
                          </Link>
                        </Tooltip>
                      </TableCell>
                      <TableCell className="cell-style">
                        <Tooltip
                          title={
                            row.value === 0 ? "100 HEWE" : row.value + " AMC"
                          }
                          arrow
                        >
                          <span>
                            {row.value === 0 ? "100 HEWE" : row.value + " AMC"}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell className="cell-style">{row.txnFee}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        <div className="bottom-content">
          <p className="last-block-content">
            <img src={bulb} alt="logo" height={12.5} width={12.5} />
            Blocks are batches of transactions linked together via cryptographic
            hashes. Any tampering of a block invalidates subsequent blocks as
            their hashes would be changed.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default TransactionFoeBlock;
